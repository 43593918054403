import React, { useEffect, useState, useRef } from "react";
import headerlogo from "./assets/img/iskcon-abids-logo.png";
import axios from "./interceptor";
import urls from "./endpoint";
import "./style.css";
import { Button } from "react-bootstrap";
import iskconabidslogo from "./assets/img/iskcon-abids-logo.png";
import leftdesign from "./assets/img/left-design.png";
import rightdesign from "./assets/img/right-design.png";
import videoSrc from "./assets/img/fire-sparkles.mp4";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from 'react-bootstrap';
const RathYatra = () => {
  const formRef = useRef(null);
  const [devoteeData, setDevoteeData] = useState({});
  const [donorData, setDonorData] = useState({
    donorName: "",
    mobileNumber: "",
    emailId: "",
    dob: "",
  });
  const [modalShow, setModalShow] = useState(false);

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const [modalShowSuccess, setModalShowSuccess] = useState(false);

  const handleShowSuccess = () => setModalShowSuccess(true);
  const handleCloseSuccess = () => setModalShowSuccess(false);
  
  const [sevasData, setSevasData] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedSevaLabel, setSelectedSevaLabel] = useState("");
  const [errors, setErrors] = useState({});
  const [anyCheckboxSelected, setAnyCheckboxSelected] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isCheckedSevas, setIsCheckedSevas] = useState({});
  const [buttonClickedSevas, setButtonClickedSevas] = useState({});
  const [amountSevas, setAmountSevas] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [searchParams] = useSearchParams();
  const [otherAmounts, setOtherAmounts] = useState({});
  const [loading, setLoading] = useState(false);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDonate = () => {
    const newErrors = {};
    let hasError = false;

    setShowError(true);
    if (!donorData.donorName) {
      newErrors.donorName = "Donor Name is required";
      hasError = true;
    }
    if (!donorData.emailId) {
      newErrors.emailId = "Email Id is required";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
      newErrors.emailId = "Please enter a valid email address";
      hasError = true;
    }

    if (!donorData.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
      hasError = true;
    }
    if (!donorData.dob) {
      newErrors.dob = "Date of Birth is required";
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }
  };

  useEffect(() => {
    setDevoteeData((prevData) => ({
      ...prevData,
      devotee_name: donorData.donorName,
      devotee_mobile_no: donorData.mobileNumber,
      devotee_email_id: donorData.emailId,
      devotee_dob: donorData.dob,
      sevas: sevasData,
      vid: searchParams.get("vid"),
      festival_name: "Rath Yatra",
    }));
  }, [donorData, sevasData]);

  const CreateDonation = async () => {
    console.log(devoteeData, "data");
    const response = await axios.post(urls.create_donation, devoteeData);

    return response;
  };
  const updateDonation = (donationNum, paymentStatus, paymentID) => {
    
    if(paymentStatus==="paid"){
      handleShow();
      axios.post(urls.update_donation, {
        donation_no: donationNum,
        payment_status: paymentStatus,
        payment_id: paymentID,
      }).then((res)=>{
           handleClose();
           handleShowSuccess()
      }).catch((error)=>{
            console.log(error)
      })
    }else{
      axios.post(urls.update_donation, {
        donation_no: donationNum,
        payment_status: paymentStatus,
        payment_id: paymentID,
      }).then((res)=>{
           console.log(res)
      }).catch((error)=>{
            console.log(error)
      })
    }
   

  };
  const handleDonateNow = () => {
    const newErrors = {};
    let hasError = false;

    if (!donorData.donorName) {
      newErrors.donorName = "Donor Name is required";
      hasError = true;
    }
    if (!donorData.emailId) {
      newErrors.emailId = "Email Id is required";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
      newErrors.emailId = "Please enter a valid email address";
      hasError = true;
    }

    if (!donorData.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
      hasError = true;
    }
    if (!donorData.dob) {
      newErrors.dob = "Date of Birth is required";
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    axios
      .post(urls.get_token, {
        grant_type: "client_credentials",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: "*",
      })
      .then((res) => {
        Cookies.set("Token", res.data.access_token);
        const donationResponse = CreateDonation();
        donationResponse.then((res) => {
          console.log(res);
          if (res && res.status && res.status === 200) {
            var options = {
              key: process.env.REACT_APP_RZP_KEY, // Enter the Key ID generated from the Dashboard
              amount: parseInt(selectedAmount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "ISKCON ABIDS", //your business name
              description: "Payment For " + selectedSevaLabel,
              image: { iskconabidslogo },
              order_id : res.data.data.order_id,
              handler: function (response) {
                
               
               
                updateDonation(
                  res.data.data.donation_no,
                  "paid",
                  response.razorpay_payment_id
                );
                
              },
              prefill: {
                //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                name: donorData.donorName, //your customer's name
                email: donorData.emailId,
                contact: donorData.mobileNumber, //Provide the customer's phone number for better conversion rates
              },

              theme: {
                color: "#3399cc",
              },
            };

            const rzp1 = new window.Razorpay(options);

            rzp1.on("payment.failed", function (response) {
              updateDonation(
                res.data.data.donation_no,
                "unpaid",
                response.error.metadata.payment_id
              );
            });

            rzp1.open();
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const mobileChangeHandler = (value,country) => {
    console.log(value,country)
  
        const countryCode = country.dialCode;
        console.log(countryCode,value)
   
    setDonorData((prevData) => ({
      ...prevData,
      mobileNumber: value,
    }));
    setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumber: null,
      }));
  };
  const changeHandler = (e) => {
    console.log(e);
    setDonorData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };

  const handleCheckboxChange = (sevaName) => (event) => {
    const isChecked = event.target.checked;

    setIsCheckedSevas((prev) => ({ ...prev, [sevaName]: isChecked }));

    if (isChecked) {
      const sevas = { name: sevaName, amount: 0 };
      setSevasData((prevData) => [...prevData, sevas]);
      if (!buttonClickedSevas[sevaName]) {
        setErrorMsg("Please select an amount");
      } else {
        setErrorMsg("");
      }
    } else {
      setSevasData((prevData) =>
        prevData.filter((seva) => seva.name !== sevaName)
      );
      const removedAmount = amountSevas[sevaName] || 0;
      setSelectedAmount((prevAmount) => prevAmount - removedAmount);
      setAmountSevas((prev) => ({ ...prev, [sevaName]: 0 }));
      setButtonClickedSevas((prev) => ({ ...prev, [sevaName]: null }));
      setErrorMsg("");
    }

    setAnyCheckboxSelected(
      Object.values({ ...isCheckedSevas, [sevaName]: isChecked }).some(Boolean)
    );
    updateSelectedSevaLabel();
  };

  const updateSelectedSevaLabel = () => {
    const selectedSevas = Object.keys(isCheckedSevas).filter(
      (seva) => isCheckedSevas[seva]
    );
    setSelectedSevaLabel(selectedSevas.join(" & "));
  };
  const handleOtherAmountChange = (sevaName) => (event) => {
    const amount = event.target.value;

    setOtherAmounts((prev) => ({ ...prev, [sevaName]: amount }));
    setButtonClickedSevas((prev) => ({ ...prev, [sevaName]: null }));
    setAmountSevas((prev) => ({ ...prev, [sevaName]: amount }));

    setSelectedAmount((prevAmount) => {
      const prevAmountForSeva = Number(amountSevas[sevaName] || 0);
      return prevAmount - prevAmountForSeva + Number(amount);
    });

    setSevasData((prevData) => {
      const updatedSevas = prevData.map((seva) => {
        if (seva.name === sevaName) {
          return { ...seva, amount: amount };
        }
        return seva;
      });
      return updatedSevas;
    });

    setErrorMsg("");
  };

  const handleButtonClick = (sevaName, buttonId, amount) => {
    const amountStr = String(amount);

    setButtonClickedSevas((prev) => {
      const prevButtonId = prev[sevaName];
      let newAmount = amountStr;

      if (prevButtonId && prevButtonId !== buttonId) {
        newAmount = (
          Number(newAmount) - Number(amountSevas[sevaName])
        ).toString();
      }

      return { ...prev, [sevaName]: buttonId };
    });

    setAmountSevas((prev) => ({ ...prev, [sevaName]: amountStr }));

    setSelectedAmount((prevAmount) => {
      const prevAmountForSeva = Number(amountSevas[sevaName] || 0);
      return prevAmount - prevAmountForSeva + Number(amountStr);
    });

    console.log(amountStr);
    setSevasData((prevData) => {
      const updatedSevas = prevData.map((seva) => {
        if (seva.name === sevaName) {
          return { ...seva, amount: amountStr };
        }
        return seva;
      });
      return updatedSevas;
    });
    setOtherAmounts((prev) => ({ ...prev, [sevaName]: "" }));
    setErrorMsg("");
  };

  return (
    <div>
      <div className="video-background">
        <video autoPlay muted loop className="background-video">
          <source src={videoSrc} type="video/mp4" />
        </video>
      </div>
      <section className="header-donate">
        <div className="container-fluid pl-md-5 pr-md-5">
          <div className="row">
            <div className="col-md-10 col-6 text-left ">
              <img src={headerlogo} className="header-logo" alt="header-logo" />
            </div>
            <div className="col-md-2 col-6  text-left">
              <button
                className="btn btn-h-donate  "
                onClick={scrollToForm}
                style={{ borderRadius: "30px", padding: "10px 25px" }}
              >
                Donate Now
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-section">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <div className="col-md-12 px-0">
              <div className="banner-image">
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/WhatsApp-Image-2024-06-07-at-11.19.15-AM.jpeg"
                  alt="Banner"
                  className="banner-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" " ref={formRef} style={{ marginTop: "8%" }}>
        <div className="  d-flex justify-content-center align-items-center">
          <div className="desk-form-div " id="offer-seva">
            <div>
              <h2 className="text-center seva">
                <img src={leftdesign} alt="" />
                Offer a Seva
                <img src={rightdesign} alt="" />
              </h2>
            </div>
            <Row>
              <Col md={6} sm={12}>
                <div>
                  <p
                    className="seva-category"
                    style={{ marginLeft: "8%", marginTop: "7%" }}
                  >
                    Special Sevas
                  </p>
                </div>

                {/* firstSeva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "2%" }}>
                    <Row>
                      <Col md={7}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange(
                            "Jagannath Darshan Pandal"
                          )}
                          checked={isCheckedSevas["Jagannath Darshan Pandal"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">
                          Jagannath Darshan Pandal
                        </label>
                        <br />
                        <label>( Rs.5,00,000 )</label>
                      </Col>

                      <Col md={4} style={{ marginTop: "0%" }}>
                        {isCheckedSevas["Jagannath Darshan Pandal"] && (
                          <input
                            type="text"
                            placeholder="Other Amount"
                            value={
                              otherAmounts["Jagannath Darshan Pandal"] || ""
                            }
                            onChange={handleOtherAmountChange(
                              "Jagannath Darshan Pandal"
                            )}
                            style={{
                              borderRadius: "20px",
                              border: "none",
                              padding: "15px",
                              paddingLeft: "10px",
                              outline: "none",
                              flex: 1,
                              width: "65%",
                              height: "75%",
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <label
                    style={{
                      marginLeft: "8%",
                      marginTop: "1%",
                      fontSize: "15px",
                    }}
                  >
                    A Grand Waterproof Pandal with Jagannath Deities presiding
                    and giving darshan to more 25,000 visitors{" "}
                  </label>

                  {isCheckedSevas["Jagannath Darshan Pandal"] && (
                    <div style={{ marginLeft: "10%", marginTop: "1%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Jagannath Darshan Pandal"] ===
                            "button1"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Jagannath Darshan Pandal",
                            "button1",
                            10000
                          )
                        }
                      >
                        {" "}
                        Rs.10,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Jagannath Darshan Pandal"] ===
                            "button2"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Jagannath Darshan Pandal",
                            "button2",
                            5000
                          )
                        }
                      >
                        {" "}
                        Rs.5,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Jagannath Darshan Pandal"] ===
                            "button3"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Jagannath Darshan Pandal",
                            "button3",
                            2500
                          )
                        }
                      >
                        {" "}
                        Rs.2,500
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Jagannath Darshan Pandal"] ===
                            "button4"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Jagannath Darshan Pandal",
                            "button4",
                            1000
                          )
                        }
                      >
                        {" "}
                        Rs.1,000
                      </button>
                    </div>
                  )}
                </div>

                {/* SecondSeva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "4%" }}>
                    <Row>
                      <Col md={5}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange("Lights Seva")}
                          checked={isCheckedSevas["Lights Seva"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">Lights Seva</label>
                        <br />
                        <label className="secondamount">( Rs. 50,000 )</label>
                      </Col>

                      <Col md={4}>
                        {isCheckedSevas["Lights Seva"] && (
                          <input
                            type="text"
                            placeholder="Other Amount"
                            value={otherAmounts["Lights Seva"] || ""}
                            onChange={handleOtherAmountChange("Lights Seva")}
                            style={{
                              borderRadius: "20px",
                              border: "none",
                              padding: "15px",
                              paddingLeft: "10px",
                              outline: "none",
                              flex: 1,
                              width: "65%",
                              height: "75%",
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <label
                    style={{
                      marginLeft: "8%",
                      marginTop: "1%",
                      fontSize: "15px",
                    }}
                  >
                    Around 400 lights to lighten whole pandal during evening
                    program
                  </label>

                  {isCheckedSevas["Lights Seva"] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Lights Seva"] === "button1"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Lights Seva", "button1", 10000)
                        }
                      >
                        {" "}
                        Rs.10,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Lights Seva"] === "button2"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Lights Seva", "button2", 5000)
                        }
                      >
                        {" "}
                        Rs.5,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Lights Seva"] === "button3"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Lights Seva", "button3", 2500)
                        }
                      >
                        {" "}
                        Rs.2,500
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Lights Seva"] === "button4"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Lights Seva", "button4", 1000)
                        }
                      >
                        {" "}
                        Rs.1,000
                      </button>
                    </div>
                  )}
                </div>

                {/* thirdSeva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "4%" }}>
                    <Row>
                      <Col md={6}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange("Sound System Seva")}
                          checked={isCheckedSevas["Sound System Seva"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">Sound System Seva</label>
                        <br />
                        <label>( Rs.2,00,000 )</label>
                      </Col>

                      <Col md={4}>
                        {isCheckedSevas["Sound System Seva"] && (
                          <input
                            type="text"
                            placeholder="Other Amount"
                            value={otherAmounts["Sound System Seva"] || ""}
                            onChange={handleOtherAmountChange(
                              "Sound System Seva"
                            )}
                            style={{
                              borderRadius: "20px",
                              border: "none",
                              padding: "15px",
                              paddingLeft: "10px",
                              outline: "none",
                              flex: 1,
                              width: "65%",
                              height: "75%",
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <label
                    style={{
                      marginLeft: "8%",
                      marginTop: "1%",
                      fontSize: "15px",
                    }}
                  >
                    Enlivening Kirtans in Rathyatra and Enlightening Lectures
                    with perfect sound system
                  </label>
                  {isCheckedSevas["Sound System Seva"] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Sound System Seva"] ===
                            "button1"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Sound System Seva",
                            "button1",
                            10000
                          )
                        }
                      >
                        {" "}
                        Rs.10,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Sound System Seva"] ===
                            "button2"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Sound System Seva",
                            "button2",
                            5000
                          )
                        }
                      >
                        {" "}
                        Rs.5,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Sound System Seva"] ===
                            "button3"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Sound System Seva",
                            "button3",
                            2500
                          )
                        }
                      >
                        {" "}
                        Rs.2,500
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Sound System Seva"] ===
                            "button4"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Sound System Seva",
                            "button4",
                            1000
                          )
                        }
                      >
                        {" "}
                        Rs.1,000
                      </button>
                    </div>
                  )}
                </div>

                {/* fourthSeva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "4%" }}>
                    <Row>
                      <Col md={4}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange("Rangoli Seva")}
                          checked={isCheckedSevas["Rangoli Seva"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">Rangoli Seva</label>
                        <br />
                        <label>( Rs.50,000 )</label>
                      </Col>

                      <Col md={4}>
                        {isCheckedSevas["Rangoli Seva"] && (
                          <input
                            type="text"
                            placeholder="Other Amount"
                            value={otherAmounts["Rangoli Seva"] || ""}
                            onChange={handleOtherAmountChange("Rangoli Seva")}
                            style={{
                              borderRadius: "20px",
                              border: "none",
                              padding: "15px",
                              paddingLeft: "10px",
                              outline: "none",
                              flex: 1,
                              width: "65%",
                              height: "65%",
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <label style={{ marginLeft: "8%", fontSize: "15px" }}>
                    Welcoming Jagannathji in streets of Hyderabad with beautiful
                    rangolis
                  </label>
                  {isCheckedSevas["Rangoli Seva"] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Rangoli Seva"] === "button1"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Rangoli Seva", "button1", 10000)
                        }
                      >
                        {" "}
                        Rs.10,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Rangoli Seva"] === "button2"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Rangoli Seva", "button2", 5000)
                        }
                      >
                        {" "}
                        Rs.5,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Rangoli Seva"] === "button3"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Rangoli Seva", "button3", 2500)
                        }
                      >
                        {" "}
                        Rs.2,500
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Rangoli Seva"] === "button4"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Rangoli Seva", "button4", 1000)
                        }
                      >
                        {" "}
                        Rs.1,000
                      </button>
                    </div>
                  )}
                </div>

                {/* fifthSeva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "4%" }}>
                    <Row>
                      <Col md={6}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange(
                            "Flower Decoration Seva"
                          )}
                          checked={isCheckedSevas["Flower Decoration Seva"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">
                          {" "}
                          Flower Decoration Seva
                        </label>
                        <br />
                        <label>( Rs.2,00,000 )</label>
                      </Col>

                      <Col md={4}>
                        {isCheckedSevas["Flower Decoration Seva"] && (
                          <input
                            type="text"
                            placeholder="Other Amount"
                            value={otherAmounts["Flower Decoration Seva"] || ""}
                            onChange={handleOtherAmountChange(
                              "Flower Decoration Seva"
                            )}
                            style={{
                              borderRadius: "20px",
                              border: "none",
                              padding: "15px",
                              paddingLeft: "10px",
                              outline: "none",
                              flex: 1,
                              width: "65%",
                              height: "75%",
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <label
                    style={{
                      marginLeft: "8%",
                      marginTop: "1%",
                      fontSize: "15px",
                    }}
                  >
                    Decorating whole Ratha & Pandal with natural flowers and
                    showering flowers over Jagannath all over procession
                  </label>
                  {isCheckedSevas["Flower Decoration Seva"] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Flower Decoration Seva"] ===
                            "button1"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Flower Decoration Seva",
                            "button1",
                            10000
                          )
                        }
                      >
                        {" "}
                        Rs.10,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Flower Decoration Seva"] ===
                            "button2"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Flower Decoration Seva",
                            "button2",
                            5000
                          )
                        }
                      >
                        {" "}
                        Rs.5,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Flower Decoration Seva"] ===
                            "button3"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Flower Decoration Seva",
                            "button3",
                            2500
                          )
                        }
                      >
                        {" "}
                        Rs.2,500
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Flower Decoration Seva"] ===
                            "button4"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Flower Decoration Seva",
                            "button4",
                            1000
                          )
                        }
                      >
                        {" "}
                        Rs.1,000
                      </button>
                    </div>
                  )}
                </div>

                {/* sixthSeva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "4%" }}>
                    <Row>
                      <Col md={4}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange("Jal Seva ")}
                          checked={isCheckedSevas["Jal Seva "]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2"> Jal Seva</label>
                        <br />
                        <label>( Rs.20,000 )</label>
                      </Col>

                      <Col md={4}>
                        {isCheckedSevas["Jal Seva "] && (
                          <input
                            type="text"
                            placeholder="Other Amount"
                            value={otherAmounts["Jal Seva "] || ""}
                            onChange={handleOtherAmountChange("Jal Seva ")}
                            style={{
                              borderRadius: "20px",
                              border: "none",
                              padding: "15px",
                              paddingLeft: "10px",
                              outline: "none",
                              flex: 1,
                              width: "65%",
                              height: "75%",
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <label
                    style={{
                      marginLeft: "8%",
                      marginTop: "1%",
                      fontSize: "15px",
                    }}
                  >
                    Providing water for devotees in Pandal
                  </label>

                  {isCheckedSevas["Jal Seva "] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Jal Seva "] === "button1"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Jal Seva ", "button1", 10000)
                        }
                      >
                        {" "}
                        Rs.10,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Jal Seva "] === "button2"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Jal Seva ", "button2", 5000)
                        }
                      >
                        {" "}
                        Rs.5,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Jal Seva "] === "button3"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Jal Seva ", "button3", 2500)
                        }
                      >
                        {" "}
                        Rs.2,500
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Jal Seva "] === "button4"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Jal Seva ", "button4", 1000)
                        }
                      >
                        {" "}
                        Rs.1,000
                      </button>
                    </div>
                  )}
                </div>

                {/* 7Seva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "4%" }}>
                    <Row>
                      <Col md={5}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange("Live Broadcast Seva")}
                          checked={isCheckedSevas["Live Broadcast Seva"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">
                          {" "}
                          Live Broadcast Seva
                        </label>
                        <br />
                        <label>( Rs.75,000 )</label>
                      </Col>

                      <Col md={4}>
                        {isCheckedSevas["Live Broadcast Seva"] && (
                          <input
                            type="text"
                            placeholder="Other Amount"
                            value={otherAmounts["Live Broadcast Seva"] || ""}
                            onChange={handleOtherAmountChange(
                              "Live Broadcast Seva"
                            )}
                            style={{
                              borderRadius: "20px",
                              border: "none",
                              padding: "15px",
                              paddingLeft: "10px",
                              outline: "none",
                              flex: 1,
                              width: "65%",
                              height: "75%",
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <label
                    style={{
                      marginLeft: "8%",
                      marginTop: "1%",
                      fontSize: "15px",
                    }}
                  >
                    Lakhs of people wants to have darshan of Jagannath and will
                    be professionally streamed on YouTube and LED Screens
                  </label>
                  {isCheckedSevas["Live Broadcast Seva"] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Live Broadcast Seva"] ===
                            "button1"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Live Broadcast Seva",
                            "button1",
                            10000
                          )
                        }
                      >
                        {" "}
                        Rs.10,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Live Broadcast Seva"] ===
                            "button2"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Live Broadcast Seva",
                            "button2",
                            5000
                          )
                        }
                      >
                        {" "}
                        Rs.5,000
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Live Broadcast Seva"] ===
                            "button3"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Live Broadcast Seva",
                            "button3",
                            2500
                          )
                        }
                      >
                        {" "}
                        Rs.2,500
                      </button>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Live Broadcast Seva"] ===
                            "button4"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Live Broadcast Seva",
                            "button4",
                            1000
                          )
                        }
                      >
                        {" "}
                        Rs.1,000
                      </button>
                    </div>
                  )}
                </div>
              </Col>

              <Col>
                <div>
                  <p
                    className="seva-category"
                    style={{ marginLeft: "8%", marginTop: "7%" }}
                  >
                    Special Darshan Seva
                  </p>
                  <label style={{ marginLeft: "8%", fontSize: "15px" }}>
                    (Will receive special lamps to offer to Lord Jagannath , VIP
                    Darshan for 4 members and Special Dinner Mahaprasad )
                  </label>
                </div>

                {/* 8Seva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "2%" }}>
                    <Row>
                      <Col md={7}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange(
                            "Mukhya Yajamana Seva"
                          )}
                          checked={isCheckedSevas["Mukhya Yajamana Seva"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">
                          Mukhya Yajamana Seva
                        </label>
                        <br />
                        <label> ( Rs.50,001 )</label>
                      </Col>
                    </Row>
                  </div>
                  <label style={{ marginLeft: "8%", fontSize: "15px" }}>
                    Additional 2 Silver Lamps & Maha Jewellery of Jagannath
                  </label>
                  {isCheckedSevas["Mukhya Yajamana Seva"] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Mukhya Yajamana Seva"] ===
                            "button1"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Mukhya Yajamana Seva",
                            "button1",
                            50001
                          )
                        }
                      >
                        {" "}
                        Rs.50,001
                      </button>
                    </div>
                  )}
                </div>

                {/* 9Seva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "4%" }}>
                    <Row>
                      <Col md={7}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange("Yajamana Seva ")}
                          checked={isCheckedSevas["Yajamana Seva "]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">Yajamana Seva </label>
                        <br />
                        <label>( Rs.25,001 )</label>
                      </Col>
                    </Row>
                  </div>
                  <label
                    style={{
                      marginLeft: "8%",
                      fontSize: "15px",
                      marginTop: "1%",
                    }}
                  >
                    Additional 2 Silver Lamps
                  </label>
                  {isCheckedSevas["Yajamana Seva "] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Yajamana Seva "] === "button1"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Yajamana Seva ", "button1", 25001)
                        }
                      >
                        {" "}
                        Rs.25,001
                      </button>
                    </div>
                  )}
                </div>
                {/* 10Seva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "4%" }}>
                    <Row>
                      <Col md={7}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange("Maha Arati Seva")}
                          checked={isCheckedSevas["Maha Arati Seva"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">Maha Arati Seva </label>
                        <br />
                        <label>( Rs.15,001 )</label>
                      </Col>
                    </Row>
                  </div>
                  <label
                    style={{
                      marginLeft: "8%",
                      fontSize: "15px",
                      marginTop: "1%",
                    }}
                  >
                    Additional Silver Lamp & Conchshell
                  </label>
                  {isCheckedSevas["Maha Arati Seva"] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Maha Arati Seva"] === "button1"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Maha Arati Seva", "button1", 15001)
                        }
                      >
                        {" "}
                        Rs.15,001
                      </button>
                    </div>
                  )}
                </div>
                {/* 11Seva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "4%" }}>
                    <Row>
                      <Col md={7}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange("Chappan Bhog Seva")}
                          checked={isCheckedSevas["Chappan Bhog Seva"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">Chappan Bhog Seva</label>
                        <br />
                        <label>( Rs.11,001 )</label>
                      </Col>
                    </Row>
                  </div>
                  <label
                    style={{
                      marginLeft: "8%",
                      fontSize: "15px",
                      marginTop: "1%",
                    }}
                  >
                    Additional Silver Lamp
                  </label>
                  {isCheckedSevas["Chappan Bhog Seva"] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Chappan Bhog Seva"] ===
                            "button1"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick(
                            "Chappan Bhog Seva",
                            "button1",
                            11001
                          )
                        }
                      >
                        {" "}
                        Rs.11,001
                      </button>
                    </div>
                  )}
                </div>
                {/* 12Seva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "4%" }}>
                    <Row>
                      <Col md={7}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange("Pradipa Seva")}
                          checked={isCheckedSevas["Pradipa Seva"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">Pradipa Seva</label>
                        <br />
                        <label>( Rs.5,001 )</label>
                      </Col>
                    </Row>
                  </div>
                  <label
                    style={{
                      marginLeft: "8%",
                      fontSize: "15px",
                      marginTop: "1%",
                    }}
                  >
                    3 Brass Lamps for offering
                  </label>
                  {isCheckedSevas["Pradipa Seva"] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Pradipa Seva"] === "button2"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Pradipa Seva", "button2", 5001)
                        }
                      >
                        {" "}
                        Rs.5,001
                      </button>
                    </div>
                  )}
                </div>
                <div>
                  <p
                    className="seva-category"
                    style={{ marginLeft: "8%", marginTop: "3%" }}
                  >
                    {" "}
                    Darshan Seva
                  </p>
                  <label style={{ marginLeft: "8%", fontSize: "15px" }}>
                    (Will receive lamps to offer to Lord Jagannath , Darshan for
                    2 members and Dinner Mahaprasad )
                  </label>
                </div>
                {/* 13Seva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "2%" }}>
                    <Row>
                      <Col md={7}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange("Tridipa Seva")}
                          checked={isCheckedSevas["Tridipa Seva"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">Tridipa Seva</label>
                        <br />
                        <label>( Rs.2,501 )</label>
                      </Col>
                    </Row>
                  </div>
                  {isCheckedSevas["Tridipa Seva"] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Tridipa Seva"] === "button3"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Tridipa Seva", "button3", 2501)
                        }
                      >
                        {" "}
                        Rs.2,501
                      </button>
                    </div>
                  )}
                </div>
                {/* 13Seva */}
                <div>
                  <div style={{ marginLeft: "8%", marginTop: "2%" }}>
                    <Row>
                      <Col md={7}>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleCheckboxChange("Dipa Seva")}
                          checked={isCheckedSevas["Dipa Seva"]}
                          id="sevaCheckbox2"
                        />
                        <label className="seva-label2">Dipa Seva</label>
                        <br />
                        <label> ( Rs.1,001 ) </label>
                      </Col>
                    </Row>
                  </div>
                  {isCheckedSevas["Dipa Seva"] && (
                    <div style={{ marginLeft: "10%", marginTop: "2%" }}>
                      <button
                        className="checkbox-btn"
                        style={{
                          backgroundImage:
                            buttonClickedSevas["Dipa Seva"] === "button4"
                              ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                              : "none",
                        }}
                        onClick={() =>
                          handleButtonClick("Dipa Seva", "button4", 1001)
                        }
                      >
                        {" "}
                        Rs.1,001
                      </button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            {!anyCheckboxSelected && showError && (
              <p className="error " style={{ color: "red" }}>
                Please select at least one Seva and Amount
              </p>
            )}
            {errorMsg && showError && (
              <div style={{ color: "red", marginLeft: "10%" }}>{errorMsg}</div>
            )}

            <div className="formwrapper text-center mt-5">
              <div className="group">
                <div className="input-div ">
                  <label className="input-margin-right">Enter Name</label>
                  <input
                    type="text"
                    className="input "
                    id="donorName"
                    name="donorName"
                    value={donorData.donorName}
                    ng-model="donorName"
                    placeholder="Enter Donar Name"
                    required
                    tabIndex="1"
                    maxLength="100"
                    onChange={(e) => changeHandler(e)}
                  />
                </div>
                <div>
                  {errors.donorName && (
                    <p className="error " style={{ color: "red" }}>
                      {errors.donorName}
                    </p>
                  )}
                </div>
              </div>

              <div className="group ">
                <div className="input-div">
                  <label className="input-margin-right">Enter Email Id</label>
                  <input
                    type="text"
                    className="input"
                    id="emialID"
                    name="emailId"
                    value={donorData.emailId}
                    ng-model="emailId"
                    placeholder="Enter Email Id"
                    required
                    tabIndex="1"
                    maxLength="100"
                    onChange={(e) => changeHandler(e)}
                  />
                </div>
                <div>
                  {errors.emailId && (
                    <p className="error " style={{ color: "red" }}>
                      {errors.emailId}
                    </p>
                  )}
                </div>
              </div>
              <div className="group ">
                <div className="input-div">
                  <label className="mobile_number">Enter Mobile Number</label>
                  <PhoneInput
                    country={"in"}
                    value={donorData.mobileNumber}
                    onChange={mobileChangeHandler}
                    placeholder="Enter mobile number"
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                     
                    }}
                    dropdownStyle={{color:"black",textAlign:"left"}}
                    enableSearch={true}
                    inputStyle={ {width:windowSize.width < 767 ?"80%":"60%"}}
                    
                  />
                 
                </div>

                <div>
                  {errors.mobileNumber && (
                    <p className="error " style={{ color: "red" }}>
                      {errors.mobileNumber}
                    </p>
                  )}
                </div>
              </div>

              <div className="group ">
                <div>
                  <label className="dob">Enter Date of Birth</label>

                  <input
                    type="date"
                    className=" date-input-rath  p-2"
                    id="birthdatepicker"
                    name="dob"
                    value={donorData.dob}
                    onChange={(e) => changeHandler(e)}
                    required
                    tabIndex="1"
                  />
                </div>
                <div>
                  {errors.dob && (
                    <p className="error " style={{ color: "red" }}>
                      {errors.dob}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
              <p className="pg-note desk text-center payment-message mt-5 tax-note">
                By continuing, you are agreeing to
                <a
                  href="https://iskconhyderabad.com/terms-and-conditions/"
                  target="_blank"
                  style={{ marginLeft: "1%" }}
                >
                  Terms of Use and Privacy Policy
                </a>
              </p>
              <div className="text-center">
                {console.log(selectedAmount)}
                {selectedAmount !== 0 ? (
                  <Button
                    className="btn btn-h-donate"
                    variant="none"
                    style={{ marginTop: "3%", borderRadius: "30px" }}
                    onClick={handleDonateNow}
                  >
                    Donate Rs-{selectedAmount}
                  </Button>
                ) : (
                  <Button
                    className="btn btn-h-donate pl-2"
                    style={{ borderRadius: "30px" }}
                    variant="none"
                    onClick={handleDonate}
                  >
                    Donate Now
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
       
        </div>
        <style jsx>{`
                .loading-spinner-container {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                   
                    z-index: 9999;
                }
            `}</style>
      </section>

      <section>
        <div style={{ marginTop: "5%" }}>
          <h5 className="dec-h5 text-center">
            {" "}
            The history of ISKCON Abids Hyderabad, it is the South Indian
            headquarters for ISKCON in India
          </h5>
          <p className="desc-para mt-2 mb-3">
            The magnificent Sri Sri Radha-Madana-mohana temple at Nampally
            Station Road, inaugurated by Swami Prabhupada, will serve as a
            center of the cultural, spiritual, educational and social activities
            given to uplift the lives of people here. The devotees over here
            will hold seminars in colleges, factories, business centers,
            universities, schools, etc., to teach the techniques of
            spiritualizing the day-to-day life. Deity worship accompanied by the
            constant chanting of the holy names will be a special feature at the
            ISKCON Abids Hyderabad. Besides, there will be daily classes in
            Sanskrit, Bhagavad-gita, Bhagavatam, and the Upanisads.
          </p>

          <p className="desc-para mt-2 mb-3">
            <span className="dec-h5"> Srila Prabhupada</span> , the
            founder-acharya of ISKCON introduced the Ratha Yatra festival in
            ISKCON Abids Hyderabad. He said, “If you participate in these
            chariot festivals and see the Deities riding on these chariots, you
            will go back home, back to Godhead at the end of this life.”
          </p>
          <p className="desc-para mt-2 mb-3">
            rathe ca vamanam drstva punar janma na vidyate: Simply by seeing the
            Lord on the chariot, one makes advancement for stopping the
            repetition of birth and death. To give this opportunity to everyone
            in Hyderabad, We are celebrating
            <span className="dec-h5">
              {" "}
              45th Ratha Yatra Celebrations of Sri Sri Jagannath Baladeva
              Subhadra on 7th July 2024{" "}
            </span>
            The Deities Sri Sri Jagannath Baladeva Subhadra come out on the
            chariot to bless the citizens. The chariot is decorated with a
            colourful display of lights and flowers. The decorative light
            fittings are a sight to behold. Thousands of devotees pull the
            chariot, offer their prayers, join in the kirtana and partake the
            prasadam being distributed, all along the route of the Ratha Yatra
          </p>
        </div>
      </section>
      <section>
        <div className="mt-5">
          <h3
            className="gal-h5 mb-3 mt-5 text-center "
            style={{ color: "white" }}
          >
            Gallery
          </h3>
          <div
            style={{
              backgroundColor: "#6c2e5d",
              padding: "20px 20px",
              marginLeft: "10%",
              width: "80%",
              borderRadius: " 20px",
            }}
          >
            <div className="wrapper ">
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/03-scaled.jpg"
                  alt="Brahmotsava"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/01-scaled.jpg"
                  alt="Dhvaja"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/11-scaled.jpg"
                  alt="Ekantha"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/10-scaled.jpg"
                  alt="Brahmotsava"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/09-scaled.jpg"
                  alt="Dhvaja"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/08-scaled.jpg"
                  alt="Ekantha"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/07-scaled.jpg"
                  alt="Brahmotsava"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/06-scaled.jpg"
                  alt="Dhvaja"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/05-scaled.jpg"
                  alt="Ekantha"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/04-scaled.jpg"
                  alt="Brahmotsava"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/02-scaled.jpg"
                  alt="Dhvaja"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/12-scaled.jpg"
                  alt="Ekantha"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container" style={{ marginTop: "10%" }}>
          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-8 my-auto text-center order-1 mt-4 order-sm-2">
              <img src={headerlogo} className="footer-logo" alt="footer-logo" />
            </div>
            <div className="col-md-3 col-sm-8 my-auto text-center order-3 mt-4  order-sm-1">
              <p className="mb-0 footer-para">Email: abidsiskcon@gmail.com </p>
              <p className="mb-0 footer-para">
                Copyright - 2024 All Right Reserved
              </p>
            </div>
            <div className="col-md-3 col-sm-8 my-auto text-center order-2 mt-4 order-sm-3">
              <h5 className="footer-h5">ISKCON ABIDS, HYDERABAD</h5>
              <p className="mb-0 footer-para">
                Old Kattal Mandi, Abids, Hyderabad, Telangana 500001
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
      <Modal show={modalShow} onHide={handleClose} centered 
      backdrop="static"
      keyboard={false}>
      <Modal.Header >
        <Modal.Title>Processing Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
     
        <div className="loading-spinner-container" >
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="70"
            visible={true}
          />
        </div>
     
        <p style={{marginTop:"25%",color:"blue"}}>Please Wait. Your payment is currently being processed.<br/> Do not refresh or close your browser.</p>
      </Modal.Body>
    </Modal>

    <Modal show={modalShowSuccess} onHide={handleCloseSuccess} centered>
      <Modal.Header closeButton>
        <Modal.Title>Payment Sucessful</Modal.Title>
      </Modal.Header>
      <Modal.Body>
     
     <div style={{alignItems:"center",justifyContent:"center",display:"flex"}}>
     <p style={{marginTop:"5%",color:"green",fontSize:"18px"}}>Thanks For The Payment</p>
     </div>
       
      </Modal.Body>
    </Modal>
   
      </section>
     
    </div>
  );
};
export default RathYatra;
