import React, { useEffect, useState, useRef, Alert } from "react";
import headerlogo from "./assets/img/iskcon-abids-logo.png";
import axios from "./interceptor";
import urls from "./endpoint";
import "./style.css";
import { Button } from "react-bootstrap";
import iskconabidslogo from "./assets/img/iskcon-abids-logo.png";
import leftdesign from "./assets/img/left-design.png";
import rightdesign from "./assets/img/right-design.png";

import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import krishnachanting from "./assets/gallery/janamshtami/chanting.mp3";
import audioOn from "./assets/gallery/janamshtami/audio-on.png";
import audioOff from "././assets/gallery/janamshtami/audio-off.png";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
const Janmastami = () => {
  const [devoteeData, setDevoteeData] = useState({});
  const [donorData, setDonorData] = useState({
    donorName: "",
    mobileNumber: "",
    emailId: "",
    dob: "",
    address: "",
  });
  const [modalShow, setModalShow] = useState(false);

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const [modalShowSuccess, setModalShowSuccess] = useState(false);

  const handleShowSuccess = () => setModalShowSuccess(true);
  const handleCloseSuccess = () => setModalShowSuccess(false);

  const [sevasData, setSevasData] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedSevaLabel, setSelectedSevaLabel] = useState("");
  const [errors, setErrors] = useState({});
  const [anyCheckboxSelected, setAnyCheckboxSelected] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isCheckedSevas, setIsCheckedSevas] = useState({});
  const [buttonClickedSevas, setButtonClickedSevas] = useState({});
  const [amountSevas, setAmountSevas] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const { vid } = useParams();
  const [otherAmounts, setOtherAmounts] = useState({});
  const [loading, setLoading] = useState(false);
  // const scrollToForm = () => {
  //   formRef.current.scrollIntoView({ behavior: "smooth" });
  // };

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [playInLoop, setPlayInLoop] = useState(true);

  const audioRef = useRef(new Audio(krishnachanting));

  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      audio.currentTime = 0;
      audio.play();
    };

    audio.addEventListener("ended", handleEnded, false);

    return () => {
      audio.removeEventListener("ended", handleEnded);
    };
  }, []);

  const playPause = () => {
    const audio = audioRef.current;
    const wasPlaying = isAudioPlaying;

    setIsAudioPlaying(!wasPlaying);

    if (wasPlaying) {
      audio.pause();
    } else {
      audio.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDonate = () => {
    const newErrors = {};
    let hasError = false;

    setShowError(true);
    if (!donorData.donorName) {
      newErrors.donorName = "Donor Name is required";
      hasError = true;
    }
    if (!donorData.emailId) {
      newErrors.emailId = "Email Id is required";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
      newErrors.emailId = "Please enter a valid email address";
      hasError = true;
    }

    if (!donorData.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
      hasError = true;
    }
    if (!donorData.dob) {
      newErrors.dob = "Date of Birth is required";
      hasError = true;
    }
    if (!donorData.address) {
      newErrors.address = "Address is required";
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    console.log(sevasData, "sevasData from ");
    setDevoteeData((prevData) => ({
      ...prevData,
      devotee_name: donorData.donorName,
      devotee_mobile_no: donorData.mobileNumber,
      devotee_email_id: donorData.emailId,
      devotee_dob: donorData.dob,
      devotee_address: donorData.address,
      sevas: sevasData,
      vid: vid,
      festival_name: "Janmashtami",
    }));
  }, [donorData, sevasData]);

  const CreateDonation = async () => {
    console.log(devoteeData, "data");
    try {
      console.log(devoteeData, "data");
      const response = await axios.post(urls.create_donation, devoteeData);
      return response;
    } catch (error) {
      console.error("API call failed:", error);
      alert("Error", "Failed to create donation. Please try again.");
      throw error;
    }
    // const response = await axios.post(urls.create_donation, devoteeData);

    // return response;
  };
  const updateDonation = (donationNum, paymentStatus, paymentID) => {
    if (paymentStatus === "paid") {
      handleShow();
      axios
        .post(urls.update_donation, {
          donation_no: donationNum,
          payment_status: paymentStatus,
          payment_id: paymentID,
        })
        .then((res) => {
          handleClose();
          handleShowSuccess();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(urls.update_donation, {
          donation_no: donationNum,
          payment_status: paymentStatus,
          payment_id: paymentID,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleDonateNow = () => {
    const selectedSevas = Object.keys(isCheckedSevas).filter(
      (seva) => isCheckedSevas[seva]
    );

    setSelectedSevaLabel(selectedSevas.join(" & "));
    const newErrors = {};
    let hasError = false;

    if (!donorData.donorName) {
      newErrors.donorName = "Donor Name is required";
      hasError = true;
    }
    if (!donorData.emailId) {
      newErrors.emailId = "Email Id is required";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
      newErrors.emailId = "Please enter a valid email address";
      hasError = true;
    }

    if (!donorData.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
      hasError = true;
    }
    if (!donorData.dob) {
      newErrors.dob = "Date of Birth is required";
      hasError = true;
    }
    if (!donorData.address) {
      newErrors.address = "Address is required";
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return;
    }
    console.log("hi from donate now");
    axios
      .post(urls.get_token, {
        grant_type: "client_credentials",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: "*",
      })
      .then((res) => {
        console.log(res, "from 196");
        Cookies.set("Token", res.data.access_token);
        const donationResponse = CreateDonation();

        donationResponse.then((res) => {
          console.log(res);
          if (res && res.status && res.status === 200) {
            var options = {
              key: process.env.REACT_APP_RZP_KEY, // Enter the Key ID generated from the Dashboard
              amount: parseInt(selectedAmount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "ISKCON ABIDS", //your business name
              description: "Payment For " + selectedSevaLabel,
              image: { iskconabidslogo },
              order_id: res.data.data.order_id,
              handler: function (response) {
                updateDonation(
                  res.data.data.donation_no,
                  "paid",
                  response.razorpay_payment_id
                );
              },
              prefill: {
                //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                name: donorData.donorName, //your customer's name
                email: donorData.emailId,
                contact: donorData.mobileNumber, //Provide the customer's phone number for better conversion rates
              },

              theme: {
                color: "#3399cc",
              },
            };

            const rzp1 = new window.Razorpay(options);

            rzp1.on("payment.failed", function (response) {
              updateDonation(
                res.data.data.donation_no,
                "unpaid",
                response.error.metadata.payment_id
              );
            });

            rzp1.open();
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const mobileChangeHandler = (value, country) => {
    console.log(value, country);

    const countryCode = country.dialCode;
    console.log(countryCode, value);

    setDonorData((prevData) => ({
      ...prevData,
      mobileNumber: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      mobileNumber: null,
    }));
  };
  const changeHandler = (e) => {
    console.log(e);
    setDonorData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };
  const handleOtherAmountChange = (sevaName) => (event) => {
    const amount = event.target.value.trim();
    console.log(amount, "amount from otheramount");
    const isNumeric = new RegExp("^[0-9]+$").test(amount);
    if (amount.length > 0 && isNumeric) {
      setButtonClickedSevas((prev) => {
        Object.keys(prev).forEach((key) => {
          setAmountSevas((prevAmountSevas) => {
            const amountToSubtract = Number(prevAmountSevas[key] || 0);
            setSelectedAmount((prevAmount) => prevAmount - amountToSubtract);
            const newAmountSevas = { ...prevAmountSevas };
            delete newAmountSevas[key];
            return newAmountSevas;
          });

          setSevasData((prevData) => {
            return prevData.filter((data) => data.name !== key);
          });
        });
        return {};
      });

      setOtherAmounts((prev) => ({ ...prev, [sevaName]: amount }));

      setAmountSevas((prev) => {
        const prevAmountForSeva = Number(prev[sevaName] || 0);
        setSelectedAmount(
          (prevAmount) => prevAmount - prevAmountForSeva + Number(amount)
        );
        return { ...prev, [sevaName]: amount };
      });

      setSevasData(() => {
        return [{ name: sevaName, amount: amount }];
      });

      setErrorMsg("");
    } else if (amount === "") {
      setButtonClickedSevas((prev) => {
        Object.keys(prev).forEach((key) => {
          setAmountSevas((prevAmountSevas) => {
            const amountToSubtract = Number(prevAmountSevas[key] || 0);
            setSelectedAmount((prevAmount) => prevAmount - amountToSubtract);
            const newAmountSevas = { ...prevAmountSevas };
            delete newAmountSevas[key];
            return newAmountSevas;
          });

          setSevasData((prevData) => {
            return prevData.filter((data) => data.name !== key);
          });
        });
        return {};
      });

      setOtherAmounts((prev) => ({ ...prev, [sevaName]: "" }));
      setAmountSevas((prev) => {
        const prevAmountForSeva = Number(prev[sevaName] || 0);
        setSelectedAmount((prevAmount) => prevAmount - prevAmountForSeva);
        const newAmountSevas = { ...prev };
        delete newAmountSevas[sevaName];
        return newAmountSevas;
      });

      setSevasData(() => {
        return [];
      });

      setErrorMsg("");
    } else {
      alert("Please Enter a Valid Amount");
    }
  };

  const handleButtonClick = (sevaName, buttonId, amount) => {
    const amountStr = String(amount);

    setButtonClickedSevas((prev) => {
      const prevButtonId = prev[sevaName];
      const isSameButtonClicked = prevButtonId === buttonId;

      if (isSameButtonClicked) {
        setAmountSevas((prevAmountSevas) => {
          const newAmountSevas = { ...prevAmountSevas };
          const amountToSubtract = Number(newAmountSevas[sevaName] || 0);

          setSelectedAmount((prevAmount) => prevAmount - amountToSubtract);

          delete newAmountSevas[sevaName];
          return newAmountSevas;
        });

        setSevasData((prevData) => {
          return prevData.filter((data) => data.name !== sevaName);
        });

        setIsCheckedSevas((prev) => ({ ...prev, [sevaName]: false }));
        setOtherAmounts((prev) => ({ ...prev, [sevaName]: "" }));
        setErrorMsg("");

        return {};
      } else {
        setAmountSevas((prevAmountSevas) => {
          const prevSevaName = Object.keys(prevAmountSevas).find(
            (key) => prevAmountSevas[key]
          );

          setSelectedAmount((prevAmount) => {
            const prevAmountForSeva = Number(
              prevAmountSevas[prevSevaName] || 0
            );
            const amountToAdd = Number(amountStr);
            return prevAmount - prevAmountForSeva + amountToAdd;
          });

          return { [sevaName]: amountStr };
        });

        setSevasData(() => {
          return [
            {
              name: sevaName,
              amount: amountStr,
            },
          ];
        });

        setIsCheckedSevas((prev) => {
          const newChecked = Object.keys(prev).reduce((acc, key) => {
            if (key !== sevaName) acc[key] = false;
            return acc;
          }, {});
          return { ...newChecked, [sevaName]: true };
        });

        setOtherAmounts((prev) => ({ ...prev, [sevaName]: "" }));
        setErrorMsg("");

        return { [sevaName]: buttonId };
      }
    });
  };

  return (
    <div>
      <div className="video-background" style={{ backgroundColor: "black" }}>
        {/* <video autoPlay muted loop className="background-video">
          <source src={videoSrc} type="video/mp4" />
        </video> */}
      </div>
      <section className="header-donate">
        <div className="container-fluid pl-md-5 pr-md-5">
          <div className="row">
            <div className="col-md-9 col-5 text-left ">
              <img src={headerlogo} className="header-logo" alt="header-logo" />
            </div>
            <div className="col-md-3 col-7  text-left ">
              <button
                className="btn btn-h-donate "
                onClick={playPause}
                style={{ borderRadius: "30px" }}
              >
                {isAudioPlaying ? (
                  <>
                    <marquee width="60%" direction="right" height="18px">
                      Hare Krishna Mantra
                    </marquee>
                    <img
                      src={audioOn}
                      style={{ height: "15%", width: "15%", marginLeft: "5%" }}
                      alt="Pause"
                    />
                  </>
                ) : (
                  <>
                    Hare Krishna Mantra
                    <img
                      src={audioOff}
                      style={{ height: "15%", width: "15%", marginLeft: "5%" }}
                      alt="Play"
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-section">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <div className="col-md-12 px-0">
              <div className="banner-image">
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/Janmastami-scaled.jpg"
                  alt="Banner"
                  className="banner-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" " style={{ marginTop: "8%" }}>
        <div className="  d-flex justify-content-center align-items-center">
          <div className="desk-form-div " id="offer-seva">
            <div>
              <h2 className="text-center seva">
                <img src={leftdesign} alt="" />
                Offer a Seva
                <img src={rightdesign} alt="" />
              </h2>
              <h3 className="seva-category text-center  mt-5">
                Lets Celebrate Janmashtami on 26th August 2024 (Monday)
              </h3>

              <h5 className="seva-category text-center  ">
                ** Darshan Starts From 7:30 AM to 12:00 AM Midnight **
              </h5>
            </div>
            <Row>
              <Col md={3} sm={3}>
                <div>
                  <img
                    src="http://iskconhyderabad.com/wp-content/uploads/WhatsApp_Image_2024-08-05_at_12.24.22_PM-removebg-preview-2.png"
                    alt="krishna"
                    className="krishnaImage"
                  />
                </div>
              </Col>
              <Col>
                <Row style={{ marginTop: "1%" }}>
                  <Col md={12} sm={12}>
                    <div>
                      <h4 className="  annadanam" style={{ marginTop: "2%" }}>
                        Annadanam Seva 2 Lac visitors
                      </h4>
                      <h4 className="annadanam-tag" style={{ marginTop: "2%" }}>
                        ( Please select any seva below )
                      </h4>
                    </div>

                    {/* firstSeva */}
                    <div className="seva-container">
                      <div style={{ marginLeft: "6%", marginTop: "4%" }}>
                        <Row>
                          <Col md={5}>
                            <label className="seva-label2">
                              Maha Abhishek Annadan Seva
                            </label>
                            <br />
                          </Col>
                        </Row>
                      </div>
                      <label className="seva-description">
                        Sponsor Annadanam for 5400/2551/1250/750 people and
                        Receive special opportunity to do Abhishek of Sri Sri
                        Radha Madan Mohan on Sri Krishna Janmastami (26th August
                        - 9 pm to 11 pm) and also receive a silver paduka of
                        Lord Krishna Special Darshan & Special Arcana for 4
                        members of your family
                      </label>

                      {
                        <div
                          className="amountButtonDirection"
                          style={{
                            marginTop: "2%",
                            justifyContent: "space-between",
                            marginRight: "30%",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <button
                              className="checkbox-btn"
                              style={{
                                backgroundImage:
                                  buttonClickedSevas[
                                    "Maha Abhishek Annadan Seva "
                                  ] === "button1"
                                    ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                    : "none",
                              }}
                              onClick={() =>
                                handleButtonClick(
                                  "Maha Abhishek Annadan Seva ",
                                  "button1",
                                  108000
                                )
                              }
                            >
                              {" "}
                              Rs.1,08,000
                            </button>
                            <p
                              style={{ marginLeft: "15%" }}
                              className="seva-amount-description"
                            >
                              {" "}
                              (for 5400 visitors)
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <button
                              className="checkbox-btn"
                              style={{
                                backgroundImage:
                                  buttonClickedSevas[
                                    "Maha Abhishek Annadan Seva "
                                  ] === "button2"
                                    ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                    : "none",
                              }}
                              onClick={() =>
                                handleButtonClick(
                                  "Maha Abhishek Annadan Seva ",
                                  "button2",
                                  51111
                                )
                              }
                            >
                              {" "}
                              Rs.51,111
                            </button>
                            <p
                              style={{ marginLeft: "15%" }}
                              className="seva-amount-description"
                            >
                              {" "}
                              (for 2551 visitors)
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <button
                              className="checkbox-btn"
                              style={{
                                backgroundImage:
                                  buttonClickedSevas[
                                    "Maha Abhishek Annadan Seva "
                                  ] === "button3"
                                    ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                    : "none",
                              }}
                              onClick={() =>
                                handleButtonClick(
                                  "Maha Abhishek Annadan Seva ",
                                  "button3",
                                  25001
                                )
                              }
                            >
                              {" "}
                              Rs.25,001
                            </button>
                            <p
                              style={{ marginLeft: "15%" }}
                              className="seva-amount-description"
                            >
                              (for 1250 visitors)
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <button
                              className="checkbox-btn"
                              style={{
                                backgroundImage:
                                  buttonClickedSevas[
                                    "Maha Abhishek Annadan Seva "
                                  ] === "button4"
                                    ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                    : "none",
                              }}
                              onClick={() =>
                                handleButtonClick(
                                  "Maha Abhishek Annadan Seva ",
                                  "button4",
                                  15001
                                )
                              }
                            >
                              {" "}
                              Rs.15,001
                            </button>
                            <p
                              style={{ marginLeft: "15%" }}
                              className="seva-amount-description"
                            >
                              (for 750 visitors)
                            </p>
                          </div>
                        </div>
                      }
                    </div>

                    {/* SecondSeva */}
                    <div className="seva-container">
                      <div style={{ marginLeft: "6%", marginTop: "4%" }}>
                        <Row>
                          <Col md={5}>
                            <label className="seva-label2">
                              Kalasa Abhishek Annadan Seva
                            </label>
                            <br />
                          </Col>
                        </Row>
                      </div>
                      <label className="seva-description">
                        Sponsor Annadan for 350/250 people and Receive a special
                        opportunity to do Kalasa Abhishek of Sri Sri Radha Krsna
                        on Sri Krishna Janmastami (26th August - 3pm to 8 pm)
                        and receive a photo of your family performing abhishek
                        Special Darshan & Arcana for 4 members
                      </label>

                      {
                        <div
                          className="amountButtonDirection"
                          style={{
                            marginTop: "2%",
                            justifyContent: "space-evenly",
                            marginRight: "30%",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <button
                              className="checkbox-btn"
                              style={{
                                backgroundImage:
                                  buttonClickedSevas[
                                    "Kalasa Abhishek Annadan Seva  "
                                  ] === "button1"
                                    ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                    : "none",
                              }}
                              onClick={() =>
                                handleButtonClick(
                                  "Kalasa Abhishek Annadan Seva  ",
                                  "button1",
                                  7001
                                )
                              }
                            >
                              {" "}
                              Rs.7001
                            </button>
                            <p
                              style={{ marginLeft: "15%" }}
                              className="seva-amount-description"
                            >
                              {" "}
                              (for 350 visitors)
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <button
                              className="checkbox-btn"
                              style={{
                                backgroundImage:
                                  buttonClickedSevas[
                                    "Kalasa Abhishek Annadan Seva  "
                                  ] === "button2"
                                    ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                    : "none",
                              }}
                              onClick={() =>
                                handleButtonClick(
                                  "Kalasa Abhishek Annadan Seva  ",
                                  "button2",
                                  5001
                                )
                              }
                            >
                              {" "}
                              Rs.5001
                            </button>
                            <p
                              style={{ marginLeft: "15%" }}
                              className="seva-amount-description"
                            >
                              {" "}
                              (for 250 visitors)
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                    {/* third seva*/}
                    <div className="seva-container">
                      <div style={{ marginLeft: "6%", marginTop: "4%" }}>
                        <Row>
                          <Col md={5}>
                            <label className="seva-label2">
                              Darshan Annadan Seva{" "}
                            </label>
                            <br />
                          </Col>
                        </Row>
                      </div>
                      <label className="seva-description">
                        Sponsor Annadan for 125/50/25 visitors and receive
                        special darshan for 2 members on Sri Krishna Janmastami
                        (26th August - 9 am to 6 pm)
                      </label>

                      {
                        <div
                          className="amountButtonDirection"
                          style={{
                            marginTop: "2%",
                            justifyContent: "space-around",
                            marginRight: "30%",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <button
                              className="checkbox-btn"
                              style={{
                                backgroundImage:
                                  buttonClickedSevas[
                                    "Darshan Annadan Seva "
                                  ] === "button3"
                                    ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                    : "none",
                              }}
                              onClick={() =>
                                handleButtonClick(
                                  "Darshan Annadan Seva ",
                                  "button3",
                                  2501
                                )
                              }
                            >
                              {" "}
                              Rs.2501
                            </button>
                            <p
                              style={{ marginLeft: "15%" }}
                              className="seva-amount-description"
                            >
                              {" "}
                              (for 125 visitors)
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <button
                              className="checkbox-btn"
                              style={{
                                backgroundImage:
                                  buttonClickedSevas[
                                    "Darshan Annadan Seva "
                                  ] === "button4"
                                    ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                    : "none",
                              }}
                              onClick={() =>
                                handleButtonClick(
                                  "Darshan Annadan Seva ",
                                  "button4",
                                  1001
                                )
                              }
                            >
                              {" "}
                              Rs.1001
                            </button>
                            <p
                              style={{ marginLeft: "15%" }}
                              className="seva-amount-description"
                            >
                              {" "}
                              (for 50 visitors)
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <button
                              className="checkbox-btn"
                              style={{
                                backgroundImage:
                                  buttonClickedSevas[
                                    "Darshan Annadan Seva "
                                  ] === "button5"
                                    ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                    : "none",
                              }}
                              onClick={() =>
                                handleButtonClick(
                                  "Darshan Annadan Seva ",
                                  "button5",
                                  501
                                )
                              }
                            >
                              {" "}
                              Rs.501
                            </button>
                            <p
                              style={{ marginLeft: "15%" }}
                              className="seva-amount-description"
                            >
                              (for 25 visitors)
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                boxSizing: "border-box",
              }}
            >
              {/* <h5 style={{marginTop:'2%', marginLeft:'5%',fontSize:18}}><b></b></h5> */}
              <label
                className="seva-label2"
                style={{
                  marginTop: "2%",
                  marginLeft: "6%",
                  marginBottom: "1%",
                }}
              >
                Enter any other amount for all sevas
              </label>
              <input
                type="text"
                placeholder="Other Amount"
                value={
                  otherAmounts[
                    "Darshan Annadan Seva  ,Kalasa Abhishek Annadan Seva && Maha Abhishek Annadan Seva "
                  ] || ""
                }
                onChange={handleOtherAmountChange(
                  "Darshan Annadan Seva  ,Kalasa Abhishek Annadan Seva && Maha Abhishek Annadan Seva "
                )}
                style={{
                  borderRadius: "20px",
                  border: "none",
                  padding: "15px",
                  paddingLeft: "50px",
                  outline: "none",
                  width: "100%",
                  maxWidth: "250px",
                  height: "75%",
                  boxSizing: "border-box",
                }}
                className="otherAmountSeva"
              />
            </div>

            <h4
              className="seva-category text-center mt-5 "
              style={{ marginLeft: "1%" }}
            >
              Please Provide Details for Donation
            </h4>

            {!anyCheckboxSelected && showError && (
              <p className="error " style={{ color: "red" }}>
                Please select at least one Seva and Amount
              </p>
            )}
            {errorMsg && showError && (
              <div style={{ color: "red", marginLeft: "10%" }}>{errorMsg}</div>
            )}

            <div className="formwrapper text-center mt-1">
              <div className="group">
                <div className="input-div ">
                  <label className="input-margin-right">Enter Name</label>
                  <input
                    type="text"
                    className="input "
                    id="donorName"
                    name="donorName"
                    value={donorData.donorName}
                    ng-model="donorName"
                    placeholder="Enter Donar Name"
                    required
                    tabIndex="1"
                    maxLength="100"
                    onChange={(e) => changeHandler(e)}
                  />
                </div>
                <div>
                  {errors.donorName && (
                    <p className="error " style={{ color: "red" }}>
                      {errors.donorName}
                    </p>
                  )}
                </div>
              </div>

              <div className="group ">
                <div className="input-div">
                  <label className="input-margin-right">Enter Email Id</label>
                  <input
                    type="text"
                    className="input"
                    id="emialID"
                    name="emailId"
                    value={donorData.emailId}
                    ng-model="emailId"
                    placeholder="Enter Email Id"
                    required
                    tabIndex="1"
                    maxLength="100"
                    onChange={(e) => changeHandler(e)}
                  />
                </div>
                <div>
                  {errors.emailId && (
                    <p className="error " style={{ color: "red" }}>
                      {errors.emailId}
                    </p>
                  )}
                </div>
              </div>

              <div className="group ">
                <div className="input-div">
                  <label className="mobile_number">Enter Mobile Number</label>
                  <PhoneInput
                    country={"in"}
                    value={donorData.mobileNumber}
                    onChange={mobileChangeHandler}
                    placeholder="Enter mobile number"
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    dropdownStyle={{ color: "black", textAlign: "left" }}
                    enableSearch={true}
                    inputStyle={{
                      width: windowSize.width < 767 ? "80%" : "60%",
                    }}
                  />
                </div>

                <div>
                  {errors.mobileNumber && (
                    <p className="error " style={{ color: "red" }}>
                      {errors.mobileNumber}
                    </p>
                  )}
                </div>
              </div>

              <div className="group ">
                <div>
                  <label className="dob">Enter Date of Birth</label>

                  <input
                    type="date"
                    className=" date-input-rath  p-2"
                    id="birthdatepicker"
                    name="dob"
                    value={donorData.dob}
                    onChange={(e) => changeHandler(e)}
                    required
                    tabIndex="1"
                  />
                </div>
                <div>
                  {errors.dob && (
                    <p className="error " style={{ color: "red" }}>
                      {errors.dob}
                    </p>
                  )}
                </div>
              </div>
              <div className="group ">
                <div>
                  <label className="input-margin-right">Enter Address</label>
                  <textarea
                    type="text"
                    className="textArea"
                    id="address"
                    name="address"
                    value={donorData.address}
                    ng-model="address"
                    placeholder="Enter Address"
                    required
                    tabIndex="1"
                    onChange={(e) => changeHandler(e)}
                  />
                </div>
                <div>
                  {errors.address && (
                    <p className="error " style={{ color: "red" }}>
                      {errors.address}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
              <p className="pg-note desk text-center payment-message mt-5 tax-note">
                By continuing, you are agreeing to
                <a
                  href="https://iskconhyderabad.com/terms-and-conditions/"
                  target="_blank"
                  style={{ marginLeft: "1%" }}
                >
                  Terms of Use and Privacy Policy
                </a>
              </p>
              <div className="text-center">
                {console.log(selectedAmount)}
                {selectedAmount !== 0 ? (
                  <Button
                    className="btn btn-h-donate"
                    variant="none"
                    style={{ marginTop: "3%", borderRadius: "30px" }}
                    onClick={handleDonateNow}
                  >
                    Donate Rs-{selectedAmount}
                  </Button>
                ) : (
                  <Button
                    className="btn btn-h-donate pl-2"
                    style={{ borderRadius: "30px" }}
                    variant="none"
                    onClick={handleDonate}
                  >
                    Donate Now
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <style jsx>{`
          .loading-spinner-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            z-index: 9999;
          }
        `}</style>
      </section>

      <section>
        <div style={{ marginTop: "5%" }}>
          <h5 className="dec-h5 text-center">
            {" "}
            The history of ISKCON Abids Hyderabad, it is the South Indian
            headquarters for ISKCON in India
          </h5>
          <p className="desc-para mt-2 mb-3">
            The magnificent Sri Sri Radha-Madana-mohana temple at Nampally
            Station Road, inaugurated by Swami Prabhupada, will serve as a
            center of the cultural, spiritual, educational and social activities
            given to uplift the lives of people here. The devotees over here
            will hold seminars in colleges, factories, business centers,
            universities, schools, etc., to teach the techniques of
            spiritualizing the day-to-day life. Deity worship accompanied by the
            constant chanting of the holy names will be a special feature at the
            ISKCON Abids Hyderabad. Besides, there will be daily classes in
            Sanskrit, Bhagavad-gita, Bhagavatam, and the Upanisads.
          </p>
        </div>
      </section>
      <section>
        <div className="mt-5">
          <h3
            className="gal-h5 mb-3 mt-5 text-center "
            style={{ color: "white" }}
          >
            Gallery
          </h3>
          <div
            style={{
              backgroundColor: "#6c2e5d",
              padding: "20px 20px",
              marginLeft: "10%",
              width: "80%",
              borderRadius: " 20px",
            }}
          >
            <div className="wrapper ">
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/01-2-scaled.jpg"
                  alt="Ekantha"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/04-1-scaled.jpg"
                  alt="Brahmotsava"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/DSC05369-scaled.jpg"
                  alt="Dhvaja"
                />
              </div>

              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/02-1-scaled.jpg"
                  alt="Dhvaja"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/WhatsApp-Image-2024-08-05-at-2.35.35-PM.jpeg"
                  alt="Ekantha"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/05-1-scaled.jpg"
                  alt="Brahmotsava"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/09-1-scaled.jpg"
                  alt="Brahmotsava"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/DSC05476-scaled.jpg"
                  alt="Dhvaja"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/08-1-scaled.jpg"
                  alt="Ekantha"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container" style={{ marginTop: "10%" }}>
          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-8 my-auto text-center order-1 mt-4 order-sm-2">
              <img src={headerlogo} className="footer-logo" alt="footer-logo" />
            </div>
            <div className="col-md-3 col-sm-8 my-auto text-center order-3 mt-4  order-sm-1">
              <p className="mb-0 footer-para">Email: abidsiskcon@gmail.com </p>
              <p className="mb-0 footer-para">
                Copyright - 2024 All Right Reserved
              </p>
            </div>
            <div className="col-md-3 col-sm-8 my-auto text-center order-2 mt-4 order-sm-3">
              <h5 className="footer-h5">ISKCON ABIDS, HYDERABAD</h5>
              <p className="mb-0 footer-para">
                Old Kattal Mandi, Abids, Hyderabad, Telangana 500001
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Modal
          show={modalShow}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Processing Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading-spinner-container">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="70"
                visible={true}
              />
            </div>

            <p style={{ marginTop: "25%", color: "blue" }}>
              Please Wait. Your payment is currently being processed.
              <br /> Do not refresh or close your browser.
            </p>
          </Modal.Body>
        </Modal>

        <Modal show={modalShowSuccess} onHide={handleCloseSuccess} centered>
          <Modal.Header closeButton>
            <Modal.Title>Payment Sucessful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p style={{ marginTop: "5%", color: "green", fontSize: "18px" }}>
                Thanks For The Payment
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </div>
  );
};
export default Janmastami;
