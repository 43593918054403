import React, { useEffect, useState, useRef } from "react";
import headerlogo from './assets/img/iskcon-abids-logo.png'


import skatitle from "./assets/img/ska-title.png"
import bgcolor from './assets/img/bg-color.png'
import './style.css'
import brahmotsava from "./assets/gallery/brahmotsava-yajna/01.jpg"
import dhvaja from "./assets/gallery/dhvaja-arohana/01.jpg"
import ekantha from "./assets/gallery/ekantha-seva/01.jpg"
import garuda from "./assets/gallery/garuda-vahana/01.jpg"
import kalasha from "./assets/gallery/kalasha-abhisheka/01.jpg"
import maha from "./assets/gallery/maha-kumbhabhisheka/01.jpg"
import mahas from "./assets/gallery/maha-samprokshana/01.jpg"
import prahlada from "./assets/gallery/prahlada-narasimha-maha-abhisheka/01.jpg"
import srinivasa from "./assets/gallery/srinivasa-govinda-maha-abhisheka/01.jpg"
import vahana from "./assets/gallery/vahana-utsava/01.jpg"
import gal1 from "./assets/gallery/gallery1/01.jpg"
import gal2 from "./assets/gallery/gallery2/01.jpg"
import { Button } from "react-bootstrap"
import iskconabidslogo from './assets/img/iskcon-abids-logo.png'
import leftdesign from './assets/img/left-design.png'
import rightdesign from './assets/img/right-design.png'
import borderImage from './assets/img/border.png'

const NarasimhaChaturdhashi = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const formRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState();
    const [donorData, setdonorData] = useState({})
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [isCheckedSeva1, setIsCheckedSeva1] = useState(false);
    const [buttonClickedSeva1, setButtonClickedSeva1] = useState('');
    const [isCheckedSeva2, setIsCheckedSeva2] = useState(false);
    const [buttonClickedSeva2, setButtonClickedSeva2] = useState('');
    const [amountSeva1, setAmountSeva1] = useState(0);
    const [amountSeva2, setAmountSeva2] = useState(0);
    const [anyCheckboxSelected, setAnyCheckboxSelected] = useState(false);
    const [showError, setShowError] = useState(false)
    const [selectedSevaLabel, setSelectedSevaLabel] = useState('');
    const [errors, setErrors] = useState({
        donorName: "",
        emailId: "",
        mobileNumber: "",
    });
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const scrollToForm = () => {
        console.log("scrolltoform")
        formRef.current.scrollIntoView({ behavior: "smooth" });

    };


    const handleAmountSelect = (amount) => {
        setSelectedAmount(amount);
    }
    const handleDonate = () => {
        const newErrors = {};
        let hasError = false;

        setShowError(true)
        if (!donorData.donorName) {
            newErrors.donorName = "Donor Name is required";
            hasError = true;
        }
        if (!donorData.emailId) {
            newErrors.emailId = "Email Id is required";
            hasError = true;
        } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
            newErrors.emailId = "Please enter a valid email address";
            hasError = true;
        }

        if (!donorData.mobileNumber) {
            newErrors.mobileNumber = "Mobile number is required";
            hasError = true;
        }

        if (hasError) {
            setErrors(newErrors);
            return;
        }
    }
    const handleDonateNow = () => {
        const newErrors = {};
        let hasError = false;

        if (!donorData.donorName) {
            newErrors.donorName = "Donor Name is required";
            hasError = true;
        }
        if (!donorData.emailId) {
            newErrors.emailId = "Email Id is required";
            hasError = true;
        } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
            newErrors.emailId = "Please enter a valid email address";
            hasError = true;
        }

        if (!donorData.mobileNumber) {
            newErrors.mobileNumber = "Mobile number is required";
            hasError = true;
        }

        if (hasError) {
            setErrors(newErrors);
            return;
        }

        console.log(donorData.donorName)
        console.log(donorData.emailId)
        console.log(donorData.mobileNumber)
        console.log(donorData)
        console.log(typeof (selectedAmount), "amount")
        console.log(selectedOption, "option")

        var options = {
            "key": process.env.REACT_APP_RZP_KEY, // Enter the Key ID generated from the Dashboard
            "amount": parseInt(selectedAmount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "ISKCON ABIDS", //your business name
            "description": "Payment For " + selectedSevaLabel,
            "image": { iskconabidslogo },
            "handler": function (response) {
                window.location.reload();
            },
            "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                "name": donorData.donorName, //your customer's name
                "email": donorData.emailId,
                "contact": donorData.mobileNumber  //Provide the customer's phone number for better conversion rates 
            },

            "theme": {
                "color": "#3399cc"
            }
        };
        console.log(options)
        const rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            alert('payment failed please try again');
        });

        rzp1.open();


        console.log("Donated", selectedAmount);
    }
    const changeHandler = (e) => {
        console.log(e.target.value)

        setdonorData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value
        }))
        setErrors((prevErrors) => ({
            ...prevErrors,
            [e.target.name]: "",
        }));
    }
    const handleCheckboxChange = (isCheckedSeva1, isCheckedSeva2) => {
        setAnyCheckboxSelected(isCheckedSeva1 || isCheckedSeva2);
    };
    const handleCheckboxChangeSeva1 = (event) => {
        setIsCheckedSeva1(event.target.checked);
        setButtonClickedSeva1('');
        setAmountSeva1(0);
        setSelectedAmount(selectedAmount - amountSeva1);
        setSelectedOption(isCheckedSeva2 || event.target.checked);
        handleCheckboxChange(event.target.checked, isCheckedSeva2);
        updateSelectedSevaLabel(event.target.checked, isCheckedSeva2);

    };

    const handleCheckboxChangeSeva2 = (event) => {
        setIsCheckedSeva2(event.target.checked);
        setButtonClickedSeva2('');
        setAmountSeva2(0);
        setSelectedAmount(selectedAmount - amountSeva2);
        setSelectedOption(isCheckedSeva1 || event.target.checked);
        handleCheckboxChange(isCheckedSeva1, event.target.checked);
        updateSelectedSevaLabel(isCheckedSeva1, event.target.checked);

    };
    const updateSelectedSevaLabel = (isCheckedSeva1, isCheckedSeva2) => {
        if (isCheckedSeva1 && isCheckedSeva2) {
            setSelectedSevaLabel('Narasimha Yagna Seva & Narasimha Jayanti Sringar Seva');
        } else if (isCheckedSeva1) {
            setSelectedSevaLabel('Narasimha Yagna Seva');
        } else if (isCheckedSeva2) {
            setSelectedSevaLabel('Narasimha Jayanti Sringar Seva');
        } else {
            setSelectedSevaLabel('');
        }
    };

    const handleButtonClickSeva1 = (buttonId, content) => {
        setButtonClickedSeva1(buttonId);
        console.log(content)
        setAmountSeva1(parseInt(content));
        setSelectedAmount(amountSeva2 + parseInt(content));

    };

    const handleButtonClickSeva2 = (buttonId, content) => {
        setButtonClickedSeva2(buttonId);
        setAmountSeva2(parseInt(content));
        setSelectedAmount(amountSeva1 + parseInt(content));
    };
    return (
        <div style={{
            backgroundImage: `url(${bgcolor})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}>


            <section className="header-donate">
                <div className="container-fluid pl-md-5 pr-md-5">
                    <div className="row">

                        <div className="col-md-10 col-7 text-left ">
                            <img src={headerlogo} className="header-logo" alt="header-logo" />
                        </div>
                        <div className="col-md-2 col-5  text-left">
                            <button className="btn btn-h-donate  donate-btn " onClick={scrollToForm} style={{  borderRadius: "30px",padding: "10px 25px"}} >Donate Now</button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="banner-section">
                <div className="container-fluid px-0">
                    <div className="row mx-0">
                        <div className="col-md-12 px-0">
                            <div className="banner-image">


                                <video autoPlay muted loop className="video-container desktop-video">
                                    <source src="https://iskconhyderabad.com/wp-content/uploads/narsima-1.mp4" type="video/mp4" className="video-class" />
                                </video>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>

                <div className="row justify-content-center align-items-center" style={{ marginTop: "-5%" }}>


                    <div className="col-md-6 col-12 text-center" >
                        <img src={skatitle} style={{ height: "99%", width: "99%", marginTop: "20%" }} alt="" className="offer-img" />

                    </div>
                    <div >
                        <h2 className="date text-center"><b>WEDNESDAY, 22 MAY </b></h2>
                    </div>
                    <img src={borderImage} style={{ height: "100%", width: "40%", marginTop: "1%" }} alt="" />

                    <div  className="text-center">
                        <h1 className="dec-h1 "><b>Narasimha Yajna <span className="from">  (FROM 5 PM TO 6 PM) <br />  <span className="arati "> FROM 6 PM ONWARDS
                                 ABHISHEK     |    KATHA     |     MAHA ARATI    |     MAHAPRASADAM    <br />
                        </span></span></b></h1>

                    </div>
                    <div>

                    </div>
                </div>

            </section>
            <section className="container " ref={formRef} style={{ marginTop: "8%" }}>

                <div className=" pt-md-3 pt-3 pb-md-3 pb-0 d-flex justify-content-center align-items-center">
                    <div className="desk-form-div " id="offer-seva">
                        <div>
                            <h2 className="text-center seva"><img src={leftdesign} alt="" />Offer a Seva<img src={rightdesign} alt="" /></h2>

                        </div>

                        <div className="sevas mt-5" style={{ marginLeft: "8%" }}>
                            <input type="checkbox" className="custom-checkbox" onChange={handleCheckboxChangeSeva1} checked={isCheckedSeva1} id="sevaCheckbox1" /> <label className="seva-label1">Narasimha Yagna Seva</label>
                        </div>
                        {isCheckedSeva1 && (
                            <div style={{ marginLeft: "10%" }}>
                                <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva1 === 'button1' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva1('button1', '11000')}> Rs.11,000 For 2 People</button>
                                <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva1 === 'button2' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva1('button2', '21000')}> Rs.21,000 For 4 People</button>
                            </div>
                        )}

                        <div style={{ marginLeft: "8%", marginTop: "2%" }}>
                            <input type="checkbox" className="custom-checkbox" onChange={handleCheckboxChangeSeva2} checked={isCheckedSeva2} id="sevaCheckbox2" /> <label className="seva-label2">Narasimha Jayanti Sringar Seva</label>
                        </div>
                        {isCheckedSeva2 && (
                            <div style={{ marginLeft: "10%" }}>
                                <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva2 === 'button1' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva2('button1', '5000')}> Rs.5000 </button>
                                <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva2 === 'button2' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva2('button2', '3500')}> Rs.3500 </button>
                                <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva2 === 'button3' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva2('button3', '2500')}> Rs.2500 </button>
                            </div>
                        )}
                        {!anyCheckboxSelected && showError && (
                            <p className="error " style={{ color: "red" }}>Please select at least one seva</p>
                        )}


                        <div className="formwrapper text-center mt-5">
                            <div className="group">
                                <div className="input-div ">


                                    <label htmlFor="donorName" className="input-label input-label-form-donar tax-note" >Donar Name </label>
                                    <input type="text" className="input " id="donorName" name="donorName" value={donorData.donorName}
                                        ng-model="donorName" placeholder="Enter Donar Name" required tabIndex="1" maxLength="100" onChange={(e) => changeHandler(e)} />
                                </div>
                                <div >
                                    {errors.donorName && <p className="error " style={{ color: "red" }}>{errors.donorName}</p>}
                                </div>
                            </div>


                            <div className="group ">
                                <div className="input-div">
                                    <label htmlFor="emailId" className="input-label input-label-form-email tax-note" >Email Id</label>
                                    <input type="text" className="input" id="emialID" name="emailId" value={donorData.emailId}
                                        ng-model="emailId" placeholder="Enter Email Id" required tabIndex="1" maxLength="100" onChange={(e) => changeHandler(e)} />
                                </div>
                                <div >
                                    {errors.emailId && <p className="error " style={{ color: "red" }}>{errors.emailId}</p>}
                                </div>
                            </div>
                            <div className="group ">
                                <div className="input-div">


                                    <label htmlFor="mobileNumber" className=" input-label input-label-form-mobile tax-note" >Mobile Number</label>
                                    <input type="text" className="input" id="mobileNumber" name="mobileNumber" value={donorData.mobileNumber}
                                        ng-model="mobileNumber" placeholder="Enter Mobile Number" required tabIndex="1" maxLength="10" onChange={(e) => changeHandler(e)} />
                                </div>

                                <div >
                                    {errors.mobileNumber && <p className="error " style={{ color: "red" }}>{errors.mobileNumber}</p>}
                                </div>
                            </div>

                            <div className="group ">
                                <div>



                                    <div>
                                        <label htmlFor="birthdatepicker" className="input-label-form-dob tax-note" >Date Of Birth</label>

                                    </div>
                                    <input type="date" className=" date-input  p-2" id="birthdatepicker" name="dateOfBirth" value={donorData.dateOfBirth} onChange={(e) => changeHandler(e)}
                                        required tabIndex="1" />
                                </div>


                            </div>
                        </div>


                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                            <p className="pg-note desk text-center payment-message mt-5 tax-note">
                                By continuing, you are agreeing to
                                <a href="https://iskconhyderabad.com/terms-and-conditions/"
                                    target="_blank" style={{ marginLeft: "1%" }}>
                                    Terms of Use and Privacy Policy</a>
                            </p>
                            <div className="text-center">
                                {console.log(selectedAmount)}
                                {selectedAmount !== 0 ? (
                                    <Button className="btn btn-h-donate" variant="none" style={{ marginTop: "3%", borderRadius: "30px" }} onClick={handleDonateNow}>Donate  Rs-{selectedAmount}</Button>
                                ) : <Button className="btn btn-h-donate pl-2" style={{ borderRadius: "30px" }} variant="none" onClick={handleDonate}>Donate Now</Button>}

                            </div>
                        </div>


                    </div>
                </div>

            </section>

            <section>
                <div style={{ marginTop: "5%" }}>
                    <h5 className="dec-h5 text-center"> Narasimha Caturdasi: A Day of Lord Narasimha’s Appearance</h5>
                    <p className="desc-para mt-2 mb-3">


                        Narsimha-Caturdasi is the divine appearance day of Narsimhadeva, Lord Krishna’s man-lion incarnation, who removes all obstacles on the path of devotional service out of His great compassion.

                        In the purport of 7.8.51 of srimad-bhagavatam, Srila Prabhupada writes, “the Supreme Personality of Godhead in his transcendental form of Lord Narsimhadev is always ready to kill the demons who always create disturbances in the minds of honest devotees. To spread the Krishna Consciousness movement, devotees have to face many dangers and impediments all over the world, but a faithful servant who preaches with great devotion to the lord must know that lord narsimhadev is always his protector.

                    </p>

                    <p className="desc-para mt-2 mb-3">
                        Hiranyakashipu, a demon controlling the three world, was extremely proud and he hated Lord Vishnu. But little Prahlada, his five year old son, was a great devotee of Lord Vishnu. Therefore, Hiranyakashipu tried to kill Prahlada in several ways; but failed in all his attempts because of the protection given by Lord to his dear devotee. Finally when Hiranyakashipu asked his little son where the Lord resides, Prahlada replied that the Lord resides everywhere. Mocking this response, Hiranyakashipu broke a pillar in his palace, and the Lord appeared from that pillar in his half man-half lion incarnation – Narasimha. Lord Narsimhadev is therefore worshipped by the devotees as their Supreme protector and they also pray that He vanquishes all obstacles they may face on the path of devotional service. So it is with much enthusiasm that the devotees come to together to worship the Lord on this day of His appearance in the material world.

                        Anyone can offer pray to the Lord Narasimha on this occasion of Narasimha Caturdasi. The reason behind this special worship is to request the Lord to guide all of us during the hard times just like he guided Prahlad.
                    </p>

                </div>
            </section>
            <section>
                <div className="mt-5">
                    <h3 className="gal-h5 mb-3 mt-5 text-center " style={{ color: "white" }}>Gallery</h3>
                    <div style={{
                        backgroundColor: "#6c2e5d", padding: "20px 20px", marginLeft: "10%", width: "80%",
                        borderRadius: " 20px"
                    }}>

                        <div className="wrapper ">
                            <div >
                                <img src={brahmotsava} alt="Brahmotsava" />
                            </div>
                            <div  >
                                <img src={dhvaja} alt="Dhvaja" />
                            </div>
                            <div >
                                <img src={ekantha} alt="Ekantha" />
                            </div>
                            <div  >
                                <img src={garuda} alt="Brahmotsava" />
                            </div>
                            <div  >
                                <img src={kalasha} alt="Dhvaja" />
                            </div>
                            <div >
                                <img src={maha} alt="Ekantha" />
                            </div>
                            <div  >
                                <img src={mahas} alt="Brahmotsava" />
                            </div>
                            <div  >
                                <img src={gal1} alt="Dhvaja" />
                            </div>
                            <div >
                                <img src={gal2} alt="Ekantha" />
                            </div>
                            <div  >
                                <img src={prahlada} alt="Brahmotsava" />
                            </div>
                            <div  >
                                <img src={vahana} alt="Dhvaja" />
                            </div>
                            <div >
                                <img src={srinivasa} alt="Ekantha" />
                            </div>
                        </div>






                    </div>

                </div>

            </section>
            <section>
                <div className="container" style={{ marginTop: "10%" }} >
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-sm-8 my-auto text-center order-1 mt-4 order-sm-2">
                            <img src={headerlogo} className="footer-logo" alt="footer-logo" />
                        </div>
                        <div className="col-md-3 col-sm-8 my-auto text-center order-3 mt-4  order-sm-1">
                            <p className="mb-0 footer-para">Email: abidsiskcon@gmail.com </p>
                            <p className="mb-0 footer-para">Copyright - 2024 All Right Reserved</p>
                        </div>
                        <div className="col-md-3 col-sm-8 my-auto text-center order-2 mt-4 order-sm-3">
                            <h5 className="footer-h5">ISKCON ABIDS, HYDERABAD</h5>
                            <p className="mb-0 footer-para">Old Kattal Mandi, Abids, Hyderabad, Telangana 500001</p>
                        </div>
                    </div>
                </div>
            </section>






        </div>
    )
}
export default NarasimhaChaturdhashi
