import React, { useEffect, useState, useRef } from "react";
import headerlogo from './assets/img/iskcon-abids-logo.png'


import skatitle from "./assets/img/boat.png"
import bgcolor from './assets/img/bg-color.png'
import './style.css'
import boat1 from "./assets/gallery/boat-fest/01.JPG"
import boat2 from "./assets/gallery/boat-fest/02.JPG"
import boat3 from "./assets/gallery/boat-fest/03.JPG"
import boat4 from "./assets/gallery/boat-fest/04.JPG"
import boat5 from "./assets/gallery/boat-fest/05.JPG"
import boat6 from "./assets/gallery/boat-fest/06.JPG"
import boat7 from "./assets/gallery/boat-fest/07.JPG"
import boat8 from "./assets/gallery/boat-fest/08.JPG"
import boat9 from "./assets/gallery/boat-fest/09.JPG"
import boat10 from "./assets/gallery/boat-fest/10.JPG"
import boat11 from "./assets/gallery/boat-fest/11.JPG"
import boat12 from "./assets/gallery/boat-fest/12.JPG"
import { Button, Col, Row } from "react-bootstrap"
import iskconabidslogo from './assets/img/iskcon-abids-logo.png'
import leftdesign from './assets/img/left-design.png'
import rightdesign from './assets/img/right-design.png'
import borderImage from './assets/img/border.png'

const BoatFestival = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const formRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState();
    const [donorData, setdonorData] = useState({})
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [isCheckedSeva1, setIsCheckedSeva1] = useState(false);
    const [buttonClickedSeva1, setButtonClickedSeva1] = useState('');
    const [isCheckedSeva2, setIsCheckedSeva2] = useState(false);
    const [buttonClickedSeva2, setButtonClickedSeva2] = useState('');
    const [isCheckedSeva3, setIsCheckedSeva3] = useState(false);
    const [buttonClickedSeva3, setButtonClickedSeva3] = useState('');
    const [isCheckedSeva4, setIsCheckedSeva4] = useState(false);
    const [buttonClickedSeva4, setButtonClickedSeva4] = useState('');
    const [amountSeva1, setAmountSeva1] = useState(0);
    const [amountSeva2, setAmountSeva2] = useState(0);
    const [amountSeva3, setAmountSeva3] = useState(0);
    const [amountSeva4, setAmountSeva4] = useState(0);
    const [anyCheckboxSelected, setAnyCheckboxSelected] = useState(false);
    const [showError, setShowError] = useState(false)
    const [selectedSevaLabel, setSelectedSevaLabel] = useState('');
    const [errors, setErrors] = useState({
        donorName: "",
        emailId: "",
        mobileNumber: "",
    });
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const scrollToForm = () => {
        console.log("scrolltoform")
        formRef.current.scrollIntoView({ behavior: "smooth" });

    };


    const handleAmountSelect = (amount) => {
        setSelectedAmount(amount);
    }
    const handleDonate = () => {
        const newErrors = {};
        let hasError = false;

        setShowError(true)
        if (!donorData.donorName) {
            newErrors.donorName = "Donor Name is required";
            hasError = true;
        }
        if (!donorData.emailId) {
            newErrors.emailId = "Email Id is required";
            hasError = true;
        } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
            newErrors.emailId = "Please enter a valid email address";
            hasError = true;
        }

        if (!donorData.mobileNumber) {
            newErrors.mobileNumber = "Mobile number is required";
            hasError = true;
        }

        if (hasError) {
            setErrors(newErrors);
            return;
        }
    }
    const handleDonateNow = () => {
        const newErrors = {};
        let hasError = false;

        if (!donorData.donorName) {
            newErrors.donorName = "Donor Name is required";
            hasError = true;
        }
        if (!donorData.emailId) {
            newErrors.emailId = "Email Id is required";
            hasError = true;
        } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
            newErrors.emailId = "Please enter a valid email address";
            hasError = true;
        }

        if (!donorData.mobileNumber) {
            newErrors.mobileNumber = "Mobile number is required";
            hasError = true;
        }

        if (hasError) {
            setErrors(newErrors);
            return;
        }

        console.log(donorData.donorName)
        console.log(donorData.emailId)
        console.log(donorData.mobileNumber)
        console.log(donorData)
        console.log(typeof (selectedAmount), "amount")
        // console.log(selectedOption, "option")

        var options = {
            "key": process.env.REACT_APP_RZP_KEY, // Enter the Key ID generated from the Dashboard
            "amount": parseInt(selectedAmount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "ISKCON ABIDS", //your business name
            "description": "Payment For " + selectedSevaLabel,
            "image": { iskconabidslogo },
            "handler": function (response) {
                window.location.reload();
            },
            "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                "name": donorData.donorName, //your customer's name
                "email": donorData.emailId,
                "contact": donorData.mobileNumber  //Provide the customer's phone number for better conversion rates 
            },

            "theme": {
                "color": "#3399cc"
            }
        };
        console.log(options)
        const rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            alert('payment failed please try again');
        });

        rzp1.open();


        console.log("Donated", selectedAmount);
    }
    const changeHandler = (e) => {
        console.log(e.target.value)

        setdonorData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value
        }))
        setErrors((prevErrors) => ({
            ...prevErrors,
            [e.target.name]: "",
        }));
    }
    const handleCheckboxChange = (isCheckedSeva1, isCheckedSeva2, isCheckedSeva3, isCheckedSeva4) => {
        setAnyCheckboxSelected(isCheckedSeva1 || isCheckedSeva2 || isCheckedSeva3 || isCheckedSeva4);
    };
    const handleCheckboxChangeSeva1 = (event) => {
        setIsCheckedSeva1(event.target.checked);
        setButtonClickedSeva1('');
        setAmountSeva1(0);
        setSelectedAmount(selectedAmount - amountSeva1);
        setSelectedOption(isCheckedSeva2 || event.target.checked);
        handleCheckboxChange(event.target.checked, isCheckedSeva2, isCheckedSeva3, isCheckedSeva4);
        updateSelectedSevaLabel(event.target.checked, isCheckedSeva2, isCheckedSeva3, isCheckedSeva4);

    };

    const handleCheckboxChangeSeva2 = (event) => {
        setIsCheckedSeva2(event.target.checked);
        setButtonClickedSeva2('');
        setAmountSeva2(0);
        setSelectedAmount(selectedAmount - amountSeva2);
        setSelectedOption(isCheckedSeva1 || event.target.checked);
        handleCheckboxChange(isCheckedSeva1, event.target.checked, isCheckedSeva3, isCheckedSeva4);
        updateSelectedSevaLabel(isCheckedSeva1, event.target.checked, isCheckedSeva3, isCheckedSeva4);

    };
    const handleCheckboxChangeSeva3 = (event) => {
        setIsCheckedSeva3(event.target.checked);
        setButtonClickedSeva3('');
        setAmountSeva3(0);
        setSelectedAmount(selectedAmount - amountSeva3);
        setSelectedOption(isCheckedSeva1 || event.target.checked);
        handleCheckboxChange(isCheckedSeva1, isCheckedSeva2, event.target.checked, isCheckedSeva4);
        updateSelectedSevaLabel(isCheckedSeva1, isCheckedSeva2, event.target.checked, isCheckedSeva4);

    };
    const handleCheckboxChangeSeva4 = (event) => {
        setIsCheckedSeva4(event.target.checked);
        setButtonClickedSeva4('');
        setAmountSeva4(0);
        setSelectedAmount(selectedAmount - amountSeva4);
        setSelectedOption(isCheckedSeva1 || event.target.checked);
        handleCheckboxChange(isCheckedSeva1, isCheckedSeva2, isCheckedSeva3, event.target.checked,);
        updateSelectedSevaLabel(isCheckedSeva1, isCheckedSeva2, isCheckedSeva3, event.target.checked,);

    };
    const updateSelectedSevaLabel = (isCheckedSeva1, isCheckedSeva2, isCheckedSeva3, isCheckedSeva4) => {
        if (isCheckedSeva1 && isCheckedSeva2 && isCheckedSeva3 && isCheckedSeva4) {
            setSelectedSevaLabel('Kund Seva, Nauka Vihar Bhog Seva, Annadaan Seva & Decoration Seva');
        } else if (isCheckedSeva1 && isCheckedSeva2 && isCheckedSeva3) {
            setSelectedSevaLabel('Kund Seva, Nauka Vihar Bhog Seva & Annadaan Seva');
        } else if (isCheckedSeva1 && isCheckedSeva2 && isCheckedSeva4) {
            setSelectedSevaLabel('Kund Seva, Nauka Vihar Bhog Seva & Decoration Seva');
        } else if (isCheckedSeva1 && isCheckedSeva3 && isCheckedSeva4) {
            setSelectedSevaLabel('Kund Seva, Annadaan Seva & Decoration Seva');
        } else if (isCheckedSeva2 && isCheckedSeva3 && isCheckedSeva4) {
            setSelectedSevaLabel('Nauka Vihar Bhog Seva, Annadaan Seva & Decoration Seva');
        } else if (isCheckedSeva1 && isCheckedSeva2) {
            setSelectedSevaLabel('Kund Seva & Nauka Vihar Bhog Seva');
        } else if (isCheckedSeva1 && isCheckedSeva3) {
            setSelectedSevaLabel('Kund Seva & Annadaan Seva');
        } else if (isCheckedSeva1 && isCheckedSeva4) {
            setSelectedSevaLabel('Kund Seva & Decoration Seva');
        } else if (isCheckedSeva2 && isCheckedSeva3) {
            setSelectedSevaLabel('Nauka Vihar Bhog Seva & Annadaan Seva');
        } else if (isCheckedSeva2 && isCheckedSeva4) {
            setSelectedSevaLabel('Nauka Vihar Bhog Seva & Decoration Seva');
        } else if (isCheckedSeva3 && isCheckedSeva4) {
            setSelectedSevaLabel('Annadaan Seva & Decoration Seva');
        } else if (isCheckedSeva1) {
            setSelectedSevaLabel('Kund Seva');
        } else if (isCheckedSeva2) {
            setSelectedSevaLabel('Nauka Vihar Bhog Seva');
        } else if (isCheckedSeva3) {
            setSelectedSevaLabel('Annadaan Seva');
        } else if (isCheckedSeva4) {
            setSelectedSevaLabel('Decoration Seva');
        } else {
            setSelectedSevaLabel('');
        }
    };


    const handleButtonClickSeva1 = (buttonId, content) => {

        setButtonClickedSeva1(buttonId);
        console.log(content)
        setAmountSeva1(parseInt(content));
        setSelectedAmount(amountSeva2 + amountSeva1 + parseInt(content) + amountSeva4);

    };

    const handleButtonClickSeva2 = (buttonId, content) => {
        setButtonClickedSeva2(buttonId);
        setAmountSeva2(parseInt(content));
        setSelectedAmount(amountSeva1 + amountSeva3 + parseInt(content) + amountSeva4);
    };
    const handleButtonClickSeva3 = (buttonId, content) => {
        setButtonClickedSeva3(buttonId);
        setAmountSeva3(parseInt(content));
        setSelectedAmount(amountSeva1 + amountSeva2 + parseInt(content) + amountSeva4);
    };
    const handleButtonClickSeva4 = (buttonId, content) => {
        setButtonClickedSeva4(buttonId);
        setAmountSeva4(parseInt(content));
        setSelectedAmount(amountSeva1 + amountSeva2 + parseInt(content) + amountSeva3);
    };
    return (
        <div style={{
            backgroundImage: `url(${bgcolor})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}>


            <section className="header-donate">
                <div className="container-fluid pl-md-5 pr-md-5">
                    <div className="row">

                        <div className="col-md-10 col-7 text-left ">
                            <img src={headerlogo} className="header-logo" alt="header-logo" />
                        </div>
                        <div className="col-md-2 col-5  text-left">
                            <button className="btn btn-h-donate  donate-btn " onClick={scrollToForm} style={{ borderRadius: "30px", padding: "10px 25px" }} >Donate Now</button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="banner-section">
                <div className="container-fluid px-0">
                    <div className="row mx-0">
                        <div className="col-md-12 px-0">
                            <div className="banner-image">


                                <video autoPlay muted loop className="video-container desktop-video">
                                    <source src="http://iskconhyderabad.com/wp-content/uploads/boat-festival.mp4" type="video/mp4" className="video-class" />
                                </video>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>

                <div className="row justify-content-center align-items-center" style={{ marginTop: "-5%" }}>


                    <div className="col-md-6 col-12 text-center" >
                        <img src={skatitle} style={{ height: "99%", width: "99%", marginTop: "20%" }} alt="" className="offer-img" />

                    </div>
                    <div className="text-center">
                        <img src={borderImage} style={{ height: "100%", width: "40%", marginTop: "1%" }} alt="" />
                    </div>

                    <div >
                        <h2 className="date text-center" ><b>FROM 24th TO 26th MAY <br /> 6:30 PM ONWARDS</b></h2>
                    </div>
                </div>

            </section>
            <section className="container " ref={formRef} style={{ marginTop: "8%" }}>
                <Row>

                <Col className="d-none d-md-block mt-5">
        <h5 className="h5Content " style={{marginTop:"6%"}}>Kund Seva</h5>
        <p className="pContent mt-2 mb-3">
            Grand kund being made for boat festival ride of Sri Sri Radha Madnamohanji
        </p>
        <h5 className="h5Content mt-3">Decoration Seva</h5>
        <p className="pContent mt-2 mb-3">Special decoration done on boat for pleasure of Lordships with chosen flowers</p>
        <h5 className="h5Content mt-3">Nauka Vihar Bhog Seva</h5>
        <p className="pContent mt-2 mb-3">
            Special delicacies to be offered 3 times each day during whole boat festival ride to our Lordships
        </p>
        <h5 className="h5Content mt-3">Annadana Seva</h5>
        <p className="pContent mt-2 mb-3">
            Offer your seva to provide Krishna prasadam to the visiting devotees of the festival
        </p>
    </Col>
                    <Col>
                        <div className=" pt-md-3 pt-3 pb-md-3 pb-0 justify-content-center align-items-center">
                            <div className="desk-form-div-boat" id="offer-seva">
                                <div>
                                    <h2 className="text-center seva"><img src={leftdesign} alt="" />Offer a Seva<img src={rightdesign} alt="" /></h2>

                                </div>

                                <div className="sevas " style={{ marginLeft: "8%" }}>
                                    <input type="checkbox"
                                        className="custom-checkbox"
                                        onChange={handleCheckboxChangeSeva1}
                                        checked={isCheckedSeva1} id="sevaCheckbox1" />
                                    <label className="seva-label1">Kund Seva</label>
                                </div>
                                {isCheckedSeva1 && (
                                    <div style={{ marginLeft: "10%" }}>
                                        <button className="checkbox-btn"
                                            style={{ backgroundImage: buttonClickedSeva1 === 'button1' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }}
                                            onClick={() => handleButtonClickSeva1('button1', '25000')}> Rs.25,000</button>
                                    </div>
                                )}


                                <div style={{ marginLeft: "8%", marginTop: "2%" }}>
                                    <input type="checkbox"
                                        className="custom-checkbox"
                                        onChange={handleCheckboxChangeSeva4}
                                        checked={isCheckedSeva4} id="sevaCheckbox2" />
                                    <label className="seva-label2">Decoration Seva</label>
                                </div>
                                {isCheckedSeva4 && (
                                    <div style={{ marginLeft: "10%" }}>
                                        <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva4 === 'button1' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }}
                                            onClick={() => handleButtonClickSeva4('button1', '20000')}> Rs.20,000</button>
                                    </div>
                                )}


                                <div style={{ marginLeft: "8%", marginTop: "2%" }}>
                                    <input type="checkbox" className="custom-checkbox"
                                        onChange={handleCheckboxChangeSeva2} checked={isCheckedSeva2}
                                        id="sevaCheckbox2" /> <label className="seva-label2">Nauka Vihar Bhog Seva</label>
                                </div>
                                {isCheckedSeva2 && (
                                    <div style={{ marginLeft: "10%" }}>
                                        <button className="checkbox-btn"
                                            style={{
                                                backgroundImage: buttonClickedSeva2 === 'button1' ?
                                                    'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none'
                                            }}
                                            onClick={() => handleButtonClickSeva2('button1', '3000')}> Rs.3000 </button>

                                    </div>
                                )}
                                <div style={{ marginLeft: "8%", marginTop: "2%" }}>
                                    <input type="checkbox" className="custom-checkbox" onChange={handleCheckboxChangeSeva3} checked={isCheckedSeva3} id="sevaCheckbox2" /> <label className="seva-label2">
                                        Annadaan Seva</label>
                                </div>
                                {isCheckedSeva3 && (
                                    <div style={{ marginLeft: "10%" }}>
                                        <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva3 === 'button1' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva3('button1', '10000')}> Rs.10,000 </button>
                                        <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva3 === 'button2' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva3('button2', '8000')}> Rs.8,000 </button>
                                        <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva3 === 'button3' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva3('button3', '5000')}> Rs.5,000 </button>
                                        <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva3 === 'button4' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva3('button3', '3000')}> Rs.3,000 </button>


                                    </div>
                                )}
                                {!anyCheckboxSelected && showError && (
                                    <p className="error " style={{ color: "red" }}>Please select at least one seva</p>
                                )}


                                <div className="formwrapper text-center mt-5">
                                    <div className="group">
                                        <div className="input-div ">


                                            <label htmlFor="donorName" className="input-label input-label-form-donar-boat tax-note" >Donar Name </label>
                                            <input type="text" className="input " id="donorName" name="donorName" value={donorData.donorName}
                                                ng-model="donorName" placeholder="Enter Donar Name" required tabIndex="1" maxLength="100" onChange={(e) => changeHandler(e)} />
                                        </div>
                                        <div >
                                            {errors.donorName && <p className="error " style={{ color: "red" }}>{errors.donorName}</p>}
                                        </div>
                                    </div>


                                    <div className="group ">
                                        <div className="input-div">
                                            <label htmlFor="emailId" className="input-label input-label-form-email-boat tax-note" >Email Id</label>
                                            <input type="text" className="input" id="emialID" name="emailId" value={donorData.emailId}
                                                ng-model="emailId" placeholder="Enter Email Id" required tabIndex="1" maxLength="100" onChange={(e) => changeHandler(e)} />
                                        </div>
                                        <div >
                                            {errors.emailId && <p className="error " style={{ color: "red" }}>{errors.emailId}</p>}
                                        </div>
                                    </div>
                                    <div className="group ">
                                        <div className="input-div">


                                            <label htmlFor="mobileNumber" className=" input-label input-label-form-mobile-boat tax-note" >Mobile Number</label>
                                            <input type="text" className="input" id="mobileNumber" name="mobileNumber" value={donorData.mobileNumber}
                                                ng-model="mobileNumber" placeholder="Enter Mobile Number" required tabIndex="1" maxLength="10" onChange={(e) => changeHandler(e)} />
                                        </div>

                                        <div >
                                            {errors.mobileNumber && <p className="error " style={{ color: "red" }}>{errors.mobileNumber}</p>}
                                        </div>
                                    </div>

                                    <div className="group ">
                                        <div>



                                            <div>
                                                <label htmlFor="birthdatepicker" className="input-label-form-dob-boat tax-note" >Date Of Birth</label>

                                            </div>
                                            <input type="date" className=" date-input-boat  p-2" id="birthdatepicker" name="dateOfBirth" value={donorData.dateOfBirth} onChange={(e) => changeHandler(e)}
                                                required tabIndex="1" />
                                        </div>


                                    </div>
                                </div>


                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                                    <p className="pg-note desk text-center payment-message mt-5 tax-note">
                                        By continuing, you are agreeing to
                                        <a href="https://iskconhyderabad.com/terms-and-conditions/"
                                            target="_blank" style={{ marginLeft: "1%" }}>
                                            Terms of Use and Privacy Policy</a>
                                    </p>
                                    <div className="text-center">
                                        {console.log(selectedAmount)}
                                        {selectedAmount !== 0 ? (
                                            <Button className="btn btn-h-donate" variant="none" style={{ marginTop: "3%", borderRadius: "30px" }} onClick={handleDonateNow}>Donate  Rs-{selectedAmount}</Button>
                                        ) : <Button className="btn btn-h-donate pl-2" style={{ borderRadius: "30px" }} variant="none" onClick={handleDonate}>Donate Now</Button>}

                                    </div>
                                </div>


                            </div>
                        </div>
                    </Col>
                </Row>


            </section>

            <section>
                <div style={{ marginTop: "5%", margin: "0 auto", padding: "20px", width: "80%", textAlign: "justify" }}>


                    <h5 className="dec-h5 text-center mt-5"> Boat Festival :   Lord Krishna’s pastimes are divine and pleasing, </h5>
                    <p className="desc-para-boat text-center  mt-2 mb-0">and we cannot understand these great pastimes with tiny material intelligence. Therefore, to understand these great pastimes and follow them in our lives, we need bonafide disciplic succession.</p>
                    <p className="desc-para-boat text-center mt-2 mb-3">Lord Krishna says in Bhagavad -Gita</p>
                    <p className="desc-para-boat text-center mt-2 mb-3">जन्म कर्म च मे दिव्यमेवं यो वेत्ति तत</p>
                    <p className="desc-para-boat text-center mt-2 mb-1">janma karma cha me divyam evaṁ yo vetti tattvataḥ
                        tyaktvā dehaṁ punar janma naiti mām eti so ’rjuna (BG 4.9)
                    </p>
                    <p className="desc-para-boat text-center mt-2 mb-3">Hey Arjun, Those who understand the divine nature of my birth and activities upon leaving the body do not have to retake rebirth. Instead, they come to my eternal abode directly.</p>
                    <h5 className="dec-h5 text-center mt-3">  Chronicle of Boat Festival</h5>
                    <p className="desc-para-boat text-center mt-2 mb-3">

                        In ISKCON (International Society For Krishna Consciousness), many festivals are organized. Thus, we can remember the Lord’s great sacred pastimes and purify our tainted hearts.</p>
                    <p className="desc-para-boat text-center mt-2 mb-3">
                        Among all these saintly Leela’s of Lord Krishna Nauka Vihar is one of them. This Leela is mentioned in a transcendental scripture named “Gopal Champu”, written by Srila Jiva Goswami. It is also mentioned in other scripture.

                    </p>
                    <h5 className="dec-h5 text-center mt-3">  About Goloka</h5>
                    <p className="desc-para-boat text-center mt-2 mb-3">
                        Goloka Vrindavan is Lord Krishna’s eternal abode, and there the pastimes go on continuously. So the root of this religious festival is also linked with the land of Krishna- ” Goloka Vrindavan”.
                    </p>
                    <h5 className="dec-h5 text-center mt-3">  Anecdote of Boat Festival</h5>
                    <p className="desc-para-boat text-center mt-2 mb-3">

                        Once all the gopis, their pots filled with yoghurt and butter, came to the shore of river Mansi. They had a desire to cross this Mansi Ganga. They met with a boatman and asked him to take them on the other side of Mansi Ganga. That boatman agreed. As soon as the boat reached afar, the boatman stopped.

                    </p>
                    <p className="desc-para-boat text-center mt-2 mb-3">
                        The gopis asked the boatman what happened to you, why you suddenly stopped? The boatman replied I am hungry. I want to eat yoghurt and butter. The gopis gave him all the curd and butter they had with them so that boatman did not stop sailing. After eating, he sailed for a while. After covering some distance, he again stopped. He said I was exhausted, wanted to take a rest. Gopis told if he does not start sailing, they will throw him from the boat.

                    </p>
                    <h5 className="dec-h5 text-center mt-3">How Radha Rani spotted Sri Krishna</h5>
                    <p className="desc-para-boat text-center mt-2 mb-3">

                        Suddenly the whole sky got filled with clouds. Winds started blowing, and the boat started shaking and moving. Gopis got scared. At the same time, Radha rani’s eyes fell on the boatman. She noticed a flute with him. She identified, it is no one else other than their beloved Krishna. All the gopis were extremely happy, knowing that this was their Krishna’s wish. It is an example of extreme reciprocating love between Lord Krishna and his devotees.

                    </p>
                    <h5 className="dec-h5 text-center mt-3">  Bond Between Gopis With Krishna</h5>
                    <p className="desc-para-boat text-center mt-2 mb-3">

                        Gopis are immense devotees of Lord Krishna. Their love for Krishna is of utmost and can not be mentioned in words. Those who reached an enormous level in spirituality can understand the love and bonding between Krishna and gopis and appreciate them.

                    </p>
                    <h5 className="dec-h5 text-center mt-3">About Boat Festival Commemoration</h5>
                    <p className="desc-para-boat text-center mt-2 mb-3">

                        The Boat festival is astonishing which will be celebrated this year at Sri Sri Radha Parthsarthi temple. This year it is also significant because due to covid we had not observed this festival last year. So this is the twelveth boat festival we are commemorating. Kaliya Krishna pond area inside the temple campus is decorated with attractive flowers, fruits, balloons and different colours. And the decoration of this place fills the heart with exhilaration. In this, many decorations and paintings are done inside water using beautiful flowers and colours.

                    </p>
                    <h5 className="dec-h5 text-center mt-3">     How Boat Festival Will Be Celebrated At ISKCON</h5>
                    <p className="desc-para-boat text-center mt-2 mb-1">

                        Sri Sri Radha Parthsarthi’s festive deity (Utsav Vigraha) is taken in a boat with Sri Lalita Ji and


                    </p>
                    <p className="desc-para-boat text-center mt-2 mb-1">
                        Sri Visakha Ji. That scene is ineffable. Lord is taken to a boat ride along with kirtan, and many preparations are offered to Lord as Bhoga. Thousands of devotees assemble and do kirtan boomingly. Lord is seated in a decorated beautiful boat and takes a ride that scene is pure bliss for devotees.

                    </p>
                    <p className="desc-para-boat text-center mt-2 mb-1">
                        We are inviting you all to come and take part in this auspicious occasion. When we participate in such a festival, our hearts thrive with darshan, kirtan, remembrance, and the company of advanced devotees makes us feel ecstatic.


                    </p>
                    <p className="desc-para-boat text-center mt-2 mb-1">
                        So come, let’s celebrate this festival together and acquire the Lord’s blessings by chanting
                    </p>
                    <p className="desc-para-boat text-center mt-2 mb-1">

                        Hare Kṛṣṇa Hare Kṛṣṇa <br />

                        Kṛṣṇa Kṛṣṇa Hare Hare <br />

                        Hare Rāma Hare Rāma <br />

                        Rāma Rāma Hare Hare
                    </p>
                </div>
            </section>
            <section>
                <div className="mt-5">
                    <h3 className="gal-h5 mb-3 mt-5 text-center " style={{ color: "white" }}>Gallery</h3>
                    <div style={{
                        backgroundColor: "#6c2e5d", padding: "20px 20px", marginLeft: "10%", width: "80%",
                        borderRadius: " 20px"
                    }}>

                        <div className="wrapper " >
                            <div  >
                                <img src={boat1} alt="boat-seva" />
                            </div>
                            <div  >
                                <img src={boat2} alt="boat-seva" />
                            </div>
                            <div >
                                <img src={boat3} alt="boat-seva" />
                            </div>
                            <div  >
                                <img src={boat4} alt="boat-seva" />
                            </div>
                            <div  >
                                <img src={boat5} alt="boat-seva" />
                            </div>
                            <div >
                                <img src={boat6} alt="Ekantha" />
                            </div>
                            <div  >
                                <img src={boat7} alt="Brahmotsava" />
                            </div>
                            <div  >
                                <img src={boat8} alt="Dhvaja" />
                            </div>
                            <div >
                                <img src={boat9} alt="Ekantha" />
                            </div>
                            <div  >
                                <img src={boat10} alt="Brahmotsava" />
                            </div>
                            <div  >
                                <img src={boat11} alt="Dhvaja" />
                            </div>
                            <div >
                                <img src={boat12} alt="Ekantha" />
                            </div>
                        </div>






                    </div>

                </div>

            </section>
            <section>
                <div className="container" style={{ marginTop: "10%" }} >
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-sm-8 my-auto text-center order-1 mt-4 order-sm-2">
                            <img src={headerlogo} className="footer-logo" alt="footer-logo" />
                        </div>
                        <div className="col-md-3 col-sm-8 my-auto text-center order-3 mt-4  order-sm-1">
                            <p className="mb-0 footer-para">Email: abidsiskcon@gmail.com </p>
                            <p className="mb-0 footer-para">Copyright - 2024 All Right Reserved</p>
                        </div>
                        <div className="col-md-3 col-sm-8 my-auto text-center order-2 mt-4 order-sm-3">
                            <h5 className="footer-h5">ISKCON ABIDS, HYDERABAD</h5>
                            <p className="mb-0 footer-para">Old Kattal Mandi, Abids, Hyderabad, Telangana 500001</p>
                        </div>
                    </div>
                </div>
            </section>






        </div>
    )
}
export default BoatFestival
