import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 

import NarasimhaChaturdhashi from './NarasimhaChaturdhashi';
import BoatFestival from './BoatFest';
import SnanaYatra from './SnanaYatra';
import RathYatra from './RathYatra';
import Janmashtami from './Janmashtami';
import PitruPaksha from './PitruPaksha';
import KartikMahotsav from './KartikMahotsav';
import GitaDaan from './GitaDaan';
function App() {
  if (window.location.pathname === "/") {
    window.location.href = "/narsimha-caturdasi";
  }

  return (
    <Router>
      <Routes> 
        <Route path="/narsimha-caturdasi" element={<NarasimhaChaturdhashi />} /> 
        <Route path="/boat-festival" element={<BoatFestival />} /> 
        <Route path="/snana-yatra" element={<SnanaYatra />} /> 
        <Route path="/rath-yatra" element={<RathYatra />} /> 
        <Route path="/janmashtami/vid/:vid" element={<Janmashtami/>} /> 
        <Route path="/pitru-paksha/vid/:vid" element={<PitruPaksha/>} /> 
        <Route path="/kartik-mahotsav/vid/:vid" element={<KartikMahotsav/>} /> 
        <Route path="/gita-daan/vid/:vid" element={<GitaDaan/>} /> 
      </Routes>
    </Router>
  );
}

export default App;
