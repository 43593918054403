import React, { useEffect, useState, useRef } from "react";
import headerlogo from './assets/img/iskcon-abids-logo.png'


import skatitle from "./assets/img/boat.png"
import bgcolor from './assets/img/bg-color.png'
import './style.css'
import boat1 from "./assets/gallery/Snanayatra/01.JPG"
import boat2 from "./assets/gallery/Snanayatra/02.JPG"
import boat3 from "./assets/gallery/Snanayatra/03.JPG"
import boat4 from "./assets/gallery/Snanayatra/04.JPG"
import boat5 from "./assets/gallery/Snanayatra/05.JPG"
import boat6 from "./assets/gallery/Snanayatra/06.JPG"
import boat7 from "./assets/gallery/Snanayatra/07.JPG"
import boat8 from "./assets/gallery/Snanayatra/08.JPG"
import boat9 from "./assets/gallery/Snanayatra/09.JPG"
import boat10 from "./assets/gallery/Snanayatra/10.JPG"
import boat11 from "./assets/gallery/Snanayatra/11.JPG"
import boat12 from "./assets/gallery/Snanayatra/12.JPG"
import { Button, Col, Row } from "react-bootstrap"
import iskconabidslogo from './assets/img/iskcon-abids-logo.png'
import leftdesign from './assets/img/left-design.png'
import rightdesign from './assets/img/right-design.png'
import borderImage from './assets/img/border.png'
import bannerImageSrc from './assets/img/bannersnana.png'
const SnanaYatra = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const formRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState();
    const [donorData, setdonorData] = useState({})
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [isCheckedSeva1, setIsCheckedSeva1] = useState(false);
    const [buttonClickedSeva1, setButtonClickedSeva1] = useState('');
    const [isCheckedSeva2, setIsCheckedSeva2] = useState(false);
    const [buttonClickedSeva2, setButtonClickedSeva2] = useState('');
    const [isCheckedSeva3, setIsCheckedSeva3] = useState(false);
    const [buttonClickedSeva3, setButtonClickedSeva3] = useState('');
    const [isCheckedSeva4, setIsCheckedSeva4] = useState(false);
    const [buttonClickedSeva4, setButtonClickedSeva4] = useState('');
    const [amountSeva1, setAmountSeva1] = useState(0);
    const [amountSeva2, setAmountSeva2] = useState(0);
    const [amountSeva3, setAmountSeva3] = useState(0);
    const [amountSeva4, setAmountSeva4] = useState(0);
    const [anyCheckboxSelected, setAnyCheckboxSelected] = useState(false);
    const [showError, setShowError] = useState(false)
    const [selectedSevaLabel, setSelectedSevaLabel] = useState('');
  
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const scrollToForm = () => {
        console.log("scrolltoform")
        formRef.current.scrollIntoView({ behavior: "smooth" });

    };


    const handleAmountSelect = (amount) => {
        setSelectedAmount(amount);
    }
    const handleDonate = () => {
        setShowError(true)
    }
    const handleDonateNow = () => {
        console.log(selectedSevaLabel,"paymentFOr")
       
        console.log(typeof (selectedAmount), "amount")
       

        var options = {
            "key": process.env.REACT_APP_RZP_KEY, // Enter the Key ID generated from the Dashboard
            "amount": parseInt(selectedAmount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "ISKCON ABIDS", //your business name
            "description": "Payment For " + selectedSevaLabel,
            "image": { iskconabidslogo },
            "handler": function (response) {
                window.location.reload();
            },
            "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                "name": donorData.donorName, //your customer's name
                "email": donorData.emailId,
                "contact": donorData.mobileNumber  //Provide the customer's phone number for better conversion rates 
            },

            "theme": {
                "color": "#3399cc"
            }
        };
        console.log(options)
        const rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            alert('payment failed please try again');
        });

        rzp1.open();


        console.log("Donated", selectedAmount);
    }
  
    const handleCheckboxChange = (isCheckedSeva1, isCheckedSeva2, isCheckedSeva3, isCheckedSeva4) => {
        setAnyCheckboxSelected(isCheckedSeva1 || isCheckedSeva2 || isCheckedSeva3 || isCheckedSeva4);
    };
    const handleCheckboxChangeSeva1 = (event) => {
        setIsCheckedSeva1(event.target.checked);
        setButtonClickedSeva1('');
        setAmountSeva1(0);
        setSelectedAmount(selectedAmount - amountSeva1);
        setSelectedOption(isCheckedSeva2 || event.target.checked);
        handleCheckboxChange(event.target.checked, isCheckedSeva2, isCheckedSeva3, isCheckedSeva4);
        updateSelectedSevaLabel(event.target.checked, isCheckedSeva2, isCheckedSeva3, isCheckedSeva4);

    };

    const handleCheckboxChangeSeva2 = (event) => {
        setIsCheckedSeva2(event.target.checked);
        setButtonClickedSeva2('');
        setAmountSeva2(0);
        setSelectedAmount(selectedAmount - amountSeva2);
        setSelectedOption(isCheckedSeva1 || event.target.checked);
        handleCheckboxChange(isCheckedSeva1, event.target.checked, isCheckedSeva3, isCheckedSeva4);
        updateSelectedSevaLabel(isCheckedSeva1, event.target.checked, isCheckedSeva3, isCheckedSeva4);

    };
    const handleCheckboxChangeSeva3 = (event) => {
        setIsCheckedSeva3(event.target.checked);
        setButtonClickedSeva3('');
        setAmountSeva3(0);
        setSelectedAmount(selectedAmount - amountSeva3);
        setSelectedOption(isCheckedSeva1 || event.target.checked);
        handleCheckboxChange(isCheckedSeva1, isCheckedSeva2, event.target.checked, isCheckedSeva4);
        updateSelectedSevaLabel(isCheckedSeva1, isCheckedSeva2, event.target.checked, isCheckedSeva4);

    };
    const handleCheckboxChangeSeva4 = (event) => {
        setIsCheckedSeva4(event.target.checked);
        setButtonClickedSeva4('');
        setAmountSeva4(0);
        setSelectedAmount(selectedAmount - amountSeva4);
        setSelectedOption(isCheckedSeva1 || event.target.checked);
        handleCheckboxChange(isCheckedSeva1, isCheckedSeva2, isCheckedSeva3, event.target.checked,);
        updateSelectedSevaLabel(isCheckedSeva1, isCheckedSeva2, isCheckedSeva3, event.target.checked,);

    };
    const updateSelectedSevaLabel = (isCheckedSeva1, isCheckedSeva2, isCheckedSeva3, isCheckedSeva4) => {
        if (isCheckedSeva1 && isCheckedSeva2 && isCheckedSeva3 && isCheckedSeva4) {
            setSelectedSevaLabel('Panchamrita Kalasa Seva, Gangajal Kalasa Seva, Annadaan Seva & Milk Kalasa Seva');
        } else if (isCheckedSeva1 && isCheckedSeva2 && isCheckedSeva3) {
            setSelectedSevaLabel('Panchamrita Kalasa Seva, Gangajal Kalasa Seva & Annadaan Seva');
        } else if (isCheckedSeva1 && isCheckedSeva2 && isCheckedSeva4) {
            setSelectedSevaLabel('Panchamrita Kalasa Seva, Gangajal Kalasa Seva & Milk Kalasa Seva');
        } else if (isCheckedSeva1 && isCheckedSeva3 && isCheckedSeva4) {
            setSelectedSevaLabel('Panchamrita Kalasa Seva, Annadaan Seva & Milk Kalasa Seva');
        } else if (isCheckedSeva2 && isCheckedSeva3 && isCheckedSeva4) {
            setSelectedSevaLabel('Gangajal Kalasa Seva, Annadaan Seva & Milk Kalasa Seva');
        } else if (isCheckedSeva1 && isCheckedSeva2) {
            setSelectedSevaLabel('Panchamrita Kalasa Seva & Gangajal Kalasa Seva');
        } else if (isCheckedSeva1 && isCheckedSeva3) {
            setSelectedSevaLabel('Panchamrita Kalasa Seva & Annadaan Seva');
        } else if (isCheckedSeva1 && isCheckedSeva4) {
            setSelectedSevaLabel('Panchamrita Kalasa Seva & Milk Kalasa Seva');
        } else if (isCheckedSeva2 && isCheckedSeva3) {
            setSelectedSevaLabel('Gangajal Kalasa Seva & Annadaan Seva');
        } else if (isCheckedSeva2 && isCheckedSeva4) {
            setSelectedSevaLabel('Gangajal Kalasa Seva & Milk Kalasa Seva');
        } else if (isCheckedSeva3 && isCheckedSeva4) {
            setSelectedSevaLabel('Annadaan Seva & Milk Kalasa Seva');
        } else if (isCheckedSeva1) {
            setSelectedSevaLabel('Panchamrita Kalasa Seva');
        } else if (isCheckedSeva2) {
            setSelectedSevaLabel('Gangajal Kalasa Seva');
        } else if (isCheckedSeva3) {
            setSelectedSevaLabel('Annadaan Seva');
        } else if (isCheckedSeva4) {
            setSelectedSevaLabel('Milk Kalasa Seva');
        } else {
            setSelectedSevaLabel('');
        }
    };
    
    


    const handleButtonClickSeva1 = (buttonId, content) => {

        setButtonClickedSeva1(buttonId);
        console.log(content)
        setAmountSeva1(parseInt(content));
        setSelectedAmount(amountSeva2 + amountSeva1 + parseInt(content) + amountSeva4);

    };

    const handleButtonClickSeva2 = (buttonId, content) => {
        setButtonClickedSeva2(buttonId);
        setAmountSeva2(parseInt(content));
        setSelectedAmount(amountSeva1 + amountSeva3 + parseInt(content) + amountSeva4);
    };
    const handleButtonClickSeva3 = (buttonId, content) => {
        setButtonClickedSeva3(buttonId);
        setAmountSeva3(parseInt(content));
        setSelectedAmount(amountSeva1 + amountSeva2 + parseInt(content) + amountSeva4);
    };
    const handleButtonClickSeva4 = (buttonId, content) => {
        setButtonClickedSeva4(buttonId);
        setAmountSeva4(parseInt(content));
        setSelectedAmount(amountSeva1 + amountSeva2 + parseInt(content) + amountSeva3);
    };
    return (
        <div style={{
            backgroundImage: `url(${bgcolor})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}>

           

            <section className="header-donate">
                <div className="container-fluid pl-md-5 pr-md-5">
                    <div className="row">

                        <div className="col-md-10 col-7 text-left ">
                            <img src={headerlogo} className="header-logo" alt="header-logo" />
                        </div>
                        <div className="col-md-2 col-5  text-left">
                            <button className="btn btn-h-donate  donate-btn " onClick={scrollToForm} style={{ borderRadius: "30px", padding: "10px 25px" }} >Donate Now</button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="banner-section">
                <div className="container-fluid px-0">
                    <div className="row mx-0">
                        <div className="col-md-12 px-0">
                            <div className="banner-image" style={{marginTop:"6%",padding:"1%"}}>

                                <img src={bannerImageSrc} alt="Banner" className="banner-img" />



                            </div>
                        </div>
                    </div>
                </div>
            </section>

          
            <section className="container " ref={formRef} style={{ marginTop: "5%" }}>
                <Row>

                    <Col className="d-none d-md-block ">
                        <h5 className="h5Content " >Panchamrita Kalasa Seva</h5>
                        <p className="pContent mt-2 mb-3">
                        Panchamrita Kalasa Seva is a  ritual involving the ceremonial offering of five nectars (milk, curd, ghee, honey, and sugar) to deities.
                        </p>
                        <h5 className="h5Content mt-3">Milk Kalasa Seva</h5>
                        <p className="pContent mt-2 mb-3">
                        Milk Kalasa Seva is a ritual where milk is offered to deities as a symbol of purity and nourishment."
                            </p>
                        <h5 className="h5Content mt-3">Gangajal kalasa Seva</h5>
                        <p className="pContent mt-2 mb-3">
                        Gangajal Kalasa Seva is a ceremony in which water from the holy river Ganga is ceremonially collected and offered to deities.
                        </p>
                        <h5 className="h5Content mt-3">Annadaan Seva</h5>
                        <p className="pContent mt-2 mb-3">
                            Offer your seva to provide Krishna prasadam to the visiting devotees of the festival
                        </p>
                    </Col>
                    <Col>
                        <div className=" pt-md-3 pt-1 pb-md-3 pb-0 justify-content-center align-items-center">
                            <div className="desk-form-div-boat" id="offer-seva">
                                <div>
                                    <h2 className="text-center seva"><img src={leftdesign} alt="" />Offer a Seva<img src={rightdesign} alt="" /></h2>

                                </div>

                                <div className="sevas " style={{ marginLeft: "8%" }}>
                                    <input type="checkbox"
                                        className="custom-checkbox"
                                        onChange={handleCheckboxChangeSeva1}
                                        checked={isCheckedSeva1} id="sevaCheckbox1" />
                                    <label className="seva-label1">Panchamrita kalasa Seva</label>
                                </div>
                                {isCheckedSeva1 && (
                                    <div style={{ marginLeft: "10%" }}>
                                        <button className="checkbox-btn"
                                            style={{ backgroundImage: buttonClickedSeva1 === 'button1' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }}
                                            onClick={() => handleButtonClickSeva1('button1', '5001')}> Rs.5,001</button>
                                    </div>
                                )}


                                <div style={{ marginLeft: "8%", marginTop: "2%" }}>
                                    <input type="checkbox"
                                        className="custom-checkbox"
                                        onChange={handleCheckboxChangeSeva4}
                                        checked={isCheckedSeva4} id="sevaCheckbox2" />
                                    <label className="seva-label2">Milk Kalasa  Seva</label>
                                </div>
                                {isCheckedSeva4 && (
                                    <div style={{ marginLeft: "10%" }}>
                                        <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva4 === 'button1' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }}
                                            onClick={() => handleButtonClickSeva4('button1', '2501')}> Rs.2,501</button>
                                    </div>
                                )}


                                <div style={{ marginLeft: "8%", marginTop: "2%" }}>
                                    <input type="checkbox" className="custom-checkbox"
                                        onChange={handleCheckboxChangeSeva2} checked={isCheckedSeva2}
                                        id="sevaCheckbox2" /> <label className="seva-label2">Gangajal Kalasa Seva </label>
                                </div>
                                {isCheckedSeva2 && (
                                    <div style={{ marginLeft: "10%" }}>
                                        <button className="checkbox-btn"
                                            style={{
                                                backgroundImage: buttonClickedSeva2 === 'button1' ?
                                                    'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none'
                                            }}
                                            onClick={() => handleButtonClickSeva2('button1', '1001')}> Rs.1,001 </button>

                                    </div>
                                )}
                                <div style={{ marginLeft: "8%", marginTop: "2%" }}>
                                    <input type="checkbox" className="custom-checkbox" onChange={handleCheckboxChangeSeva3} checked={isCheckedSeva3} id="sevaCheckbox2" /> <label className="seva-label2">
                                        Annadaan Seva</label>
                                </div>
                                {isCheckedSeva3 && (
                                    <div style={{ marginLeft: "10%" }}>
                                        <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva3 === 'button1' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva3('button1', '1001')}> Rs.1,001 </button>
                                        <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva3 === 'button2' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva3('button2', '2501')}> Rs.2,501 </button>
                                        <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva3 === 'button3' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva3('button3', '5001')}> Rs.5,001 </button>
                                        <button className="checkbox-btn" style={{ backgroundImage: buttonClickedSeva3 === 'button4' ? 'linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)' : 'none' }} onClick={() => handleButtonClickSeva3('button3', '10001')}> Rs.10,001 </button>


                                    </div>
                                )}
                                {!anyCheckboxSelected && showError && (
                                    <p className="error " style={{ color: "red" }}>Please select at least one seva</p>
                                )}

                                {/* 
                                <div className="formwrapper text-center mt-5">
                                    <div className="group">
                                        <div className="input-div ">


                                            <label htmlFor="donorName" className="input-label input-label-form-donar-boat tax-note" >Donar Name </label>
                                            <input type="text" className="input " id="donorName" name="donorName" value={donorData.donorName}
                                                ng-model="donorName" placeholder="Enter Donar Name" required tabIndex="1" maxLength="100" onChange={(e) => changeHandler(e)} />
                                        </div>
                                        <div >
                                            {errors.donorName && <p className="error " style={{ color: "red" }}>{errors.donorName}</p>}
                                        </div>
                                    </div>


                                    <div className="group ">
                                        <div className="input-div">
                                            <label htmlFor="emailId" className="input-label input-label-form-email-boat tax-note" >Email Id</label>
                                            <input type="text" className="input" id="emialID" name="emailId" value={donorData.emailId}
                                                ng-model="emailId" placeholder="Enter Email Id" required tabIndex="1" maxLength="100" onChange={(e) => changeHandler(e)} />
                                        </div>
                                        <div >
                                            {errors.emailId && <p className="error " style={{ color: "red" }}>{errors.emailId}</p>}
                                        </div>
                                    </div>
                                    <div className="group ">
                                        <div className="input-div">


                                            <label htmlFor="mobileNumber" className=" input-label input-label-form-mobile-boat tax-note" >Mobile Number</label>
                                            <input type="text" className="input" id="mobileNumber" name="mobileNumber" value={donorData.mobileNumber}
                                                ng-model="mobileNumber" placeholder="Enter Mobile Number" required tabIndex="1" maxLength="10" onChange={(e) => changeHandler(e)} />
                                        </div>

                                        <div >
                                            {errors.mobileNumber && <p className="error " style={{ color: "red" }}>{errors.mobileNumber}</p>}
                                        </div>
                                    </div>

                                    <div className="group ">
                                        <div>



                                            <div>
                                                <label htmlFor="birthdatepicker" className="input-label-form-dob-boat tax-note" >Date Of Birth</label>

                                            </div>
                                            <input type="date" className=" date-input-boat  p-2" id="birthdatepicker" name="dateOfBirth" value={donorData.dateOfBirth} onChange={(e) => changeHandler(e)}
                                                required tabIndex="1" />
                                        </div>


                                    </div>
                                </div> */}


                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                                    <p className="pg-note desk text-center payment-message mt-5 tax-note">
                                        By continuing, you are agreeing to
                                        <a href="https://iskconhyderabad.com/terms-and-conditions/"
                                            target="_blank" style={{ marginLeft: "1%" }}>
                                            Terms of Use and Privacy Policy</a>
                                    </p>
                                    <div className="text-center">
                                        {console.log(selectedAmount)}
                                        {selectedAmount !== 0 ? (
                                            <Button className="btn btn-h-donate" variant="none" style={{ marginTop: "3%", borderRadius: "30px" }} onClick={handleDonateNow}>Donate  Rs-{selectedAmount}</Button>
                                        ) : <Button className="btn btn-h-donate pl-2" style={{ borderRadius: "30px" }} variant="none" onClick={handleDonate}>Donate Now</Button>}

                                    </div>
                                </div>


                            </div>
                        </div>
                    </Col>
                </Row>


            </section>

            <section>
                <div style={{ marginTop: "5%", margin: "0 auto", padding: "20px", width: "80%", textAlign: "justify" }}>


                    <h5 className="dec-h5 text-center mt-5">  At Puri, a grand festival known as 'Snana Purnima' or 'Snana Yatra' </h5>
                    <p className="desc-para-boat text-center  mt-2 mb-0">
                        takes place on the full moon day in the month of Jyestha. It is a special occasion where Lord Jagannatha, Baladeva, and Subhadra are bathed in an abhisheka ceremony.</p>
                    <p className="desc-para-boat text-center mt-2 mb-3">According to the Skanda Purana, this tradition was established by King Indradyumna during the installation of the Deities. It is believed that seeing Lord Jagannatha on this day can absolve ourselves of our past sins, which is why thousands of devotees from around the world flock to Puri to witness this holy festival.</p>
                    <p className="desc-para-boat text-center mt-2 mb-3">Following the sacred bath ritual, the Deities take on a unique and delightful form known as 'Hati Vesha' or 'Gaja Vesha' in the evening. During this part of the festival, Lord Jagannatha and Lord Balarama are adorned with ornate elephant costumes, complete with trunks and tusks. Subhadra takes on the appearance of a beautiful lotus flower.</p>
                    <p className="desc-para-boat text-center mt-2 mb-1">
                        Just like in Puri, the Snana Yatra festival is celebrated with great enthusiasm at ISKCON Bangalore's Vaikuntha Hill. The Deities are bathed with panchamrita, pancha gavya, and other auspicious items to keep Them cool during the summer months.
                    </p>
                    <p className="desc-para-boat text-center mt-2 mb-3">Hey Arjun, Those who understand the divine nature of my birth and activities upon leaving the body do not have to retake rebirth. Instead, they come to my eternal abode directly.</p>

                    <p className="desc-para-boat text-center mt-2 mb-3">
                        To participate in the joyous festivities and receive the Lord's blessings, you can sponsor the items for the Lord's Snana Yatra and the special Gaja Vesha. By doing so, you can also join the devotees of Lord Jagannatha and participate in this special event..</p>
                   
                </div>
            </section>
            <section>
                <div className="mt-5">
                    <h3 className="gal-h5 mb-3 mt-5 text-center " style={{ color: "white" }}>Gallery</h3>
                    <div style={{
                        backgroundColor: "#6c2e5d", padding: "20px 20px", marginLeft: "10%", width: "80%",
                        borderRadius: " 20px"
                    }}>

                        <div className="wrapper " >
                            <div  >
                                <img src={boat1} alt="boat-seva"  />
                            </div>
                            <div  >
                                <img src={boat2} alt="boat-seva" />
                            </div>
                            <div >
                                <img src={boat3} alt="boat-seva" />
                            </div>
                            <div  >
                                <img src={boat4} alt="boat-seva" />
                            </div>
                            <div  >
                                <img src={boat5} alt="boat-seva" />
                            </div>
                            <div >
                                <img src={boat6} alt="Ekantha" />
                            </div>
                          
                            <div  >
                                <img src={boat10} alt="Brahmotsava" />
                            </div>
                            <div  >
                                <img src={boat11} alt="Dhvaja" />
                            </div>
                            <div >
                                <img src={boat12} alt="Ekantha" />
                            </div>
                        </div>






                    </div>

                </div>

            </section>
            <section>
                <div className="container" style={{ marginTop: "10%" }} >
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-sm-8 my-auto text-center order-1 mt-4 order-sm-2">
                            <img src={headerlogo} className="footer-logo" alt="footer-logo" />
                        </div>
                        <div className="col-md-3 col-sm-8 my-auto text-center order-3 mt-4  order-sm-1">
                            <p className="mb-0 footer-para">Email: abidsiskcon@gmail.com </p>
                            <p className="mb-0 footer-para">Copyright - 2024 All Right Reserved</p>
                        </div>
                        <div className="col-md-3 col-sm-8 my-auto text-center order-2 mt-4 order-sm-3">
                            <h5 className="footer-h5">ISKCON ABIDS, HYDERABAD</h5>
                            <p className="mb-0 footer-para">Old Kattal Mandi, Abids, Hyderabad, Telangana 500001</p>
                        </div>
                    </div>
                </div>
            </section>






        </div>
    )
}
export default SnanaYatra
