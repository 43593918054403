import axios from "axios";

import Cookies from 'js-cookie';
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
    let token = Cookies.get('Token');;
    config.headers['Authorization'] = 'Bearer '+ token;
    return config;
});

axiosInstance.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.status) {
      switch (error.response.status) {
        case 401:
            
          case 402:
          
      }
      return Promise.reject(error.response);
    }
  }
);

export default axiosInstance;